import {
  Avatar,
  AvatarFallbackText,
  Box,
  Center,
  Heading,
  HStack,
  Badge,
  BadgeText,
  Button,
  ButtonText,
} from '@gluestack-ui/themed';

export default function RoiSpeedometer(params) {
  return (
    <>
      <Heading>ROI Per Play</Heading>
      <Heading>{params.roigaugevalue}%</Heading>
      {/*<Speedometer*/}
      {/*  value={params.roigaugevalue}*/}
      {/*  max={27}*/}
      {/*  fontFamily="squada-one"*/}
      {/*  width={120}*/}
      {/*>*/}
      {/*  <Background />*/}

      {/*  <Arc arcWidth={4} />*/}

      {/*  <DangerPath angle={80} />*/}
      {/*  <Progress2 arcWidth={15} />*/}
      {/*  <Needle />*/}

      {/*  <Marks step={4} fontSize={16} lineSize={8} />*/}

      {/*  <Indicator fixValue={false}>*/}
      {/*    {(value, textProps) => (*/}
      {/*      <Text*/}
      {/*        {...textProps} // textProps has the "transform" property only*/}
      {/*        fontSize={18}*/}
      {/*        fill={'white'}*/}
      {/*        x={120 / 2 + 5}*/}
      {/*        y={120 / 2 + 35}*/}
      {/*        textAnchor="middle"*/}
      {/*        alignmentBaseline="middle"*/}
      {/*      >*/}
      {/*        {value}%*/}
      {/*      </Text>*/}
      {/*    )}*/}
      {/*  </Indicator>*/}

      {/*  <Indicator fixValue={false}>*/}
      {/*    {(value, textProps) => (*/}
      {/*      <Text*/}
      {/*        {...textProps} // textProps has the "transform" property only*/}
      {/*        fontSize={13}*/}
      {/*        fill={'white'}*/}
      {/*        x={120 / 2}*/}
      {/*        y={120 / 2 + 48}*/}
      {/*        textAnchor="middle"*/}
      {/*        alignmentBaseline="middle"*/}
      {/*      >*/}
      {/*        ROI*/}
      {/*      </Text>*/}
      {/*    )}*/}
      {/*  </Indicator>*/}
      {/*</Speedometer>*/}
    </>
  );
}
