import {
  Avatar,
  AvatarFallbackText,
  AvatarImage,
  Box,
  Center,
  Heading,
  HStack,
  Badge,
  BadgeText,
  ArrowUpIcon,
  PaperclipIcon,
  Icon,
  Button,
  ButtonText,
  Text,
  Image,
} from '@gluestack-ui/themed';
import { StyleSheet } from 'react-native';
import React from 'react';
import Ionicons from 'react-native-vector-icons/Ionicons';

const cryptoIconMap = {
  ETH: require('../../assets/eth.png'),
  DOGE: require('../../assets/doge.png'),
  BTC: require('../../assets/btc.png'),
  AVAX: require('../../assets/avax.png'),
  XLM: require('../../assets/xlm.png'),
  BCH: require('../../assets/bch.png'),
  LINK: require('../../assets/link.png'),
  UNI: require('../../assets/uni.png'),
  ETC: require('../../assets/etc.png'),
  XTZ: require('../../assets/xtz.png'),
  COMP: require('../../assets/comp.png'),
  AAVE: require('../../assets/aave.png'),
  LTC: require('../../assets/ltc.png'),
  default: require('../../assets/grin.png'),
  XRP: require('../../assets/xrp.png'),
  MATIC: require('../../assets/matic.png'),
  SOL: require('../../assets/sol.png'),
  ADA: require('../../assets/ada.png'),
  DOT: require('../../assets/dot.png'),
  SHIB: require('../../assets/shib.png'),
};

const cryptoSmallIconMap = {
  ETH: require('../../assets/eth-sm.png'),
  DOGE: require('../../assets/doge-sm.png'),
  BTC: require('../../assets/btc-sm.png'),
  AVAX: require('../../assets/avax-sm.png'),
  XLM: require('../../assets/xlm-sm.png'),
  BCH: require('../../assets/bch-sm.png'),
  LINK: require('../../assets/link-sm.png'),
  UNI: require('../../assets/uni-sm.png'),
  ETC: require('../../assets/etc-sm.png'),
  XTZ: require('../../assets/xtz-sm.png'),
  COMP: require('../../assets/comp-sm.png'),
  AAVE: require('../../assets/aave-sm.png'),
  LTC: require('../../assets/ltc-sm.png'),
  default: require('../../assets/grin-sm.png'),
  XRP: require('../../assets/xrp-sm.png'),
  MATIC: require('../../assets/matic-sm.png'),
  SOL: require('../../assets/sol-sm.png'),
  ADA: require('../../assets/ada-sm.png'),
  DOT: require('../../assets/dot-sm.png'),
  SHIB: require('../../assets/shib-sm.png'),
};

export default function CryptoAvatar(params) {
  if (params.crypto === 'cash') {
    return (
      <Avatar size={'md'}>
        <Ionicons name={'cash-outline'} color={'white'} />
      </Avatar>
    );
  }

  let value = cryptoIconMap[params.crypto];
  if (value === undefined) {
    value = cryptoIconMap['default'];
  }
  let size = 'sm';
  if (params.size !== undefined && params.size === 'sm') {
    size = 'xs';
  }

  return (
    <Image
      alt={params.crypto + ' logo'}
      size={size}
      borderRadius="$none"
      source={value}
    />
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
});
