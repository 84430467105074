import React, { useContext, useEffect } from 'react';
import {
  Button,
  ButtonText,
  Heading,
  VStack,
  Toast,
  ToastTitle,
  useToast,
  Center,
  Text,
  Box,
  ButtonGroup,
} from '@gluestack-ui/themed';

import { Linking, ScrollView, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native';

export default function Support({ route }) {
  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <SafeAreaView style={{ flex: 8 }}>
      <ScrollView>
        <Box style={styles.card}>
          <Heading size={'lg'} mb={10}>
            Support
          </Heading>

          <Text>Recipient: CryptoPilot.app</Text>
          <Text>Address:</Text>
          <Text>218 Lovvorn RD</Text>
          <Text>Carrollton, GA, 30117</Text>
          <Text>support@cryptopilot.app</Text>
          <Text>+1 404 422 2794</Text>

          <ButtonGroup mb={10} mt={10}>
            <Button
              onPress={() => Linking.openURL('https://discord.gg/kbx4jugR')}
            >
              <ButtonText>Join Our Discord</ButtonText>
            </Button>
          </ButtonGroup>
        </Box>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    //alignItems: 'center',
    // justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
});
