import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

export async function Login(provider, auth, setAuthLoading) {
  signInWithPopup(auth, provider).catch((error) => {
    console.log('heyyy error');
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log(error);
    console.log('error');
    setAuthLoading(false);

    // ...
  });
}
