import React, { useContext, useEffect } from 'react';
import {
  Button,
  Text,
  ButtonText,
  Heading,
  Center,
  Image,
  ButtonGroup,
} from '@gluestack-ui/themed';

import { StyleSheet, ImageBackground, SafeAreaView } from 'react-native';
import logo from '../../assets/LOGO2.png';

import { AuthContext } from '../../AuthProvider';
import { useNavigation } from '@react-navigation/native';

import Login from './Login';

const LoginScreen = () => {
  const navigation = useNavigation();

  useEffect(() => {}, []);

  return (
    <SafeAreaView style={styles.container}>
      <ImageBackground
        source={require('../../assets/login.png')}
        style={styles.backgroundImage}
        resizeMode="cover"
      >
        <Center mt={20} style={styles.card}>
          <Image
            alt={'CryptoPilot Icon'}
            size={'md'}
            borderRadius="$none"
            source={logo}
          />
          <Heading>CryptoPilot.app</Heading>
          <Button onPress={() => navigation.navigate('About')} variant={'link'}>
            <ButtonText>Automated Crypto Trading</ButtonText>
          </Button>
          <Login redirect={'Home'} />
        </Center>

        <Center style={styles.signupCard}>
          <Text>Don't have an account?</Text>
          <ButtonGroup>
            <Button
              onPress={() => navigation.navigate('About')}
              variant={'outline'}
            >
              <ButtonText>More Info</ButtonText>
            </Button>
            <Button onPress={() => navigation.navigate('CryptoPilotSignUp')}>
              <ButtonText>Create Account</ButtonText>
            </Button>
          </ButtonGroup>
        </Center>
      </ImageBackground>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
  },
  backgroundImage: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  googleSignInButton: {
    backgroundColor: 'blue',
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
  },
  card: {
    backgroundColor: '#fff',
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    paddingBottom: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
  signupCard: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 10,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
  buttonIcon: {
    marginRight: 5,
    marginTop: 2,
  },
});

export default LoginScreen;
