import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonText,
  Center,
  Heading,
  HStack,
  Pressable,
  Spinner,
  Text,
} from '@gluestack-ui/themed';
import { ScrollView, View } from 'react-native';
import { dashboardAPI as DashboardAPI, dashboardAPI } from '../../DashboardAPI';
import CryptoAvatar from './CryptoAvatar';
import { AuthContext } from '../../AuthProvider';
import { useNavigation } from '@react-navigation/native';

export default function SelectCryptos(params) {
  const { user, refreshMarketApps, marketApps } = useContext(AuthContext);
  const navigation = useNavigation();
  const allCryptos = {};
  const cryptos = dashboardAPI.getCryptosFromMarketList(marketApps);
  for (let i = 0; i < cryptos.length; i = i + 1) {
    allCryptos[cryptos[i]] = false;
  }
  const [cryptoSelect, setCryptoSelect] = React.useState(allCryptos);
  const [loading, setLoading] = React.useState(false);

  const handleCryptoSelection = (asset2) => {
    let select = { ...cryptoSelect };

    select[asset2] = !cryptoSelect[asset2];
    setCryptoSelect(select);
  };

  const isAnyCryptoSelect = () => {
    for (let i = 0; i < cryptos.length; i = i + 1) {
      if (cryptoSelect[cryptos[i]]) {
        return false;
      }
    }
    return true;
  };

  const submitCryptoSelection = () => {
    const cryptos_to_invest = [];
    const cryptos_to_not_invest = [];
    for (let i = 0; i < dashboardAPI.CRYPTO_CONFIG.names.length; i = i + 1) {
      if (
        cryptos.includes(dashboardAPI.CRYPTO_CONFIG.names[i]) &&
        cryptoSelect[dashboardAPI.CRYPTO_CONFIG.names[i]]
      ) {
        cryptos_to_invest.push(dashboardAPI.CRYPTO_CONFIG.names[i]);
      } else {
        cryptos_to_not_invest.push(dashboardAPI.CRYPTO_CONFIG.names[i]);
      }
    }

    dashboardAPI
      .postCryptoInvestSettingsList(
        user,
        cryptos_to_invest,
        cryptos_to_not_invest,
      )
      .then((x) => {
        navigation.navigate('AddFunds');
        refreshMarketApps(user);
      });
  };

  return (
    <>
      <Center>
        <Heading>CryptoPilot.app</Heading>
      </Center>

      <Heading>Select the Cryptos you want to invest in</Heading>

      {!loading ? (
        <ScrollView width={'100%'}>
          {cryptos.map((crypto) => (
            <Pressable
              key={crypto}
              width={'98%'}
              onPress={() => handleCryptoSelection(crypto)}
            >
              <HStack
                mt={10}
                borderColor={
                  cryptoSelect[crypto] ? '$secondary600' : '$grey900'
                }
                backgroundColor={
                  cryptoSelect[crypto] ? '$secondary500' : '#fff'
                }
                borderWidth={3}
                borderRadius={'$full'}
              >
                <CryptoAvatar crypto={crypto} />
                <Heading color={cryptoSelect[crypto] ? 'white' : 'black'}>
                  {crypto} - {DashboardAPI.CRYPTO_CONFIG.fullNames[crypto]}
                </Heading>
              </HStack>
            </Pressable>
          ))}
          {isAnyCryptoSelect() && <Text>Select One Crypto</Text>}
          <Button
            isDisabled={isAnyCryptoSelect()}
            onPress={() => submitCryptoSelection()}
            m={10}
          >
            <ButtonText>Save</ButtonText>
          </Button>
        </ScrollView>
      ) : (
        <Loading />
      )}
    </>
  );
}
