import React, { useContext, useEffect } from 'react';
import {
  Center,
  Heading,
  Box,
  Button,
  Avatar,
  VStack,
  HStack,
  RadioGroup,
  Radio,
  RadioLabel,
  RadioIndicator,
  RadioIcon,
  CircleIcon,
  Actionsheet,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetSectionHeaderText,
  Alert,
  AlertIcon,
  AlertText,
  ButtonText,
  CheckCircleIcon,
  Icon,
  AtSignIcon,
  Text,
  ActionsheetItem,
  ActionsheetItemText,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputSlot,
  InputIcon,
  InputField,
  Image,
  useToast,
  Toast,
  ToastTitle,
} from '@gluestack-ui/themed';
import { dashboardAPI } from '../DashboardAPI';
import { AuthContext } from '../AuthProvider';
import CryptoAvatar from './shared/CryptoAvatar';
import {
  SafeAreaView,
  ScrollView,
  View,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
} from 'react-native';
import Loading from './shared/Loading';
import { useNavigation } from '@react-navigation/native';
import firebaseUtils from '../Firebase';
import Login from './shared/Login';

const RobinhoodIcon = require('../assets/bitso.png');

export default function RobinhoodApiLogin({ route }) {
  const { user, marketApps, logout, marketAppsLoading, refreshMarketApps } =
    useContext(AuthContext);
  const navigation = useNavigation();

  const [robinhoodAPIKey, setRobinhoodAPIKey] = React.useState('');
  const [publicKey, setPublicKey] = React.useState('');
  const [privateKey, setPrivateKey] = React.useState('');

  const [loading, setLoading] = React.useState(true);

  function copyToClipboard(text) {
    (async () => {
      try {
        await navigator.clipboard.writeText(text);
        /* ✅ Copied successfully */

        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="info" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Public Key Copied To Clipboard</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      } catch (e) {
        /* ❌ Failed to copy (insufficient permissions) */
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable To Copy Public Key</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      }
    })();
  }

  const getKey = (user2) => {
    dashboardAPI.generateRobinhoodKey(user2).then((x) => {
      setPublicKey(x.data.public_key);
      setPrivateKey(x.data.private_key);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (user !== false) {
      (async () => {
        getKey(user);
      })();
    } else {
      setLoading(false);
    }
  }, [user]);
  const toast = useToast();

  const login = () => {
    setLoading(true);

    const value = {
      robinhood_api_key: robinhoodAPIKey,
      robinhood_public_key: publicKey,
      robinhood_private_key: privateKey,
      market_app: 'robinhood',
    };
    value['robinhood_api_key'] = dashboardAPI.encryptString(
      value['robinhood_api_key'],
    );
    value['robinhood_public_key'] = dashboardAPI.encryptString(
      value['robinhood_public_key'],
    );
    value['robinhood_private_key'] = dashboardAPI.encryptString(
      value['robinhood_private_key'],
    );

    dashboardAPI
      .postUser(user, value)
      .then((x) => {
        if (x.data.ok === 'ok') {
          toast.show({
            placement: 'top',
            render: ({ id }) => {
              return (
                <Toast nativeId={id} action="success" variant="solid">
                  <VStack space="xs">
                    <ToastTitle>
                      Robinhood API Configured Successfully
                    </ToastTitle>
                  </VStack>
                </Toast>
              );
            },
          });
          navigation.navigate('Home');
          refreshMarketApps(user);
        }
      })
      .catch((err) => {
        console.log(err);

        setLoading(false);
        setRobinhoodAPIKey('');

        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable to Login into Robinhood API</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      });
  };

  return (
    <SafeAreaView>
      {loading ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <>
          {user !== false ? (
            <Box>
              <Center style={styles.card}>
                {/*<Image*/}
                {/*  style={{ height: 120, width: 330 }}*/}
                {/*  alt={'Robinhood Icon'}*/}
                {/*  size={'xl'}*/}
                {/*  borderRadius="$none"*/}
                {/*  source={RobinhoodIcon}*/}
                {/*/>*/}
                <Heading size={'sm'}>Need Help??</Heading>
                <Button
                  onPress={() => navigation.navigate('RobinhoodGuide')}
                  variant={'outline'}
                >
                  <ButtonText>Step By Step Guide</ButtonText>
                </Button>

                <VStack w="$full" p={20}>
                  <FormControl mt={36}>
                    {publicKey === '' ? (
                      <Heading>Loading..</Heading>
                    ) : (
                      <>
                        <FormControlLabel>
                          <FormControlLabelText>
                            Public Key
                          </FormControlLabelText>
                        </FormControlLabel>
                        <Input
                          aria-label={'generated public key'}
                          size={'lg'}
                          w="$full"
                          disabled={true}
                        >
                          <InputField value={publicKey} variant="rounded" />
                        </Input>

                        <Button
                          size={'sm'}
                          variant={'outline'}
                          style={'secondary'}
                          onPress={() => copyToClipboard(publicKey)}
                          mt={20}
                        >
                          <ButtonText>COPY PUBLIC KEY</ButtonText>
                        </Button>
                      </>
                    )}

                    <FormControlLabel>
                      <FormControlLabelText>
                        Robinhood API Key
                      </FormControlLabelText>
                    </FormControlLabel>
                    <Input
                      aria-label={'Robinhood API Key'}
                      size={'lg'}
                      w="$full"
                    >
                      <InputField
                        value={robinhoodAPIKey}
                        onChangeText={setRobinhoodAPIKey}
                        variant="rounded"
                      />
                    </Input>

                    <Button
                      isDisabled={robinhoodAPIKey === ''}
                      onPress={() => login()}
                      mt={20}
                    >
                      <ButtonText>Submit</ButtonText>
                    </Button>
                  </FormControl>
                </VStack>
              </Center>
            </Box>
          ) : (
            <>
              <Center style={styles.card}>
                <Heading mb={20}>Robinhood API Config Page</Heading>
                <Text>
                  To continue configuring Robinhood API, Login to your
                  CryptoPilot.app Account
                </Text>
                <Login redirect={'RobinhoodApiLogin'} />
              </Center>
            </>
          )}
        </>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
});
