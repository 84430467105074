import React, { useContext } from 'react';
import {
  Box,
  Center,
  Heading,
  HStack,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import { Dimensions, TouchableOpacity } from 'react-native';
import CryptoAvatar from './CryptoAvatar';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { AuthContext } from '../../AuthProvider';
import { dashboardAPI } from '../../DashboardAPI';

const CryptoCard = ({ coinName, cryptoSettings, amountEarned, dateRange }) => {
  const navigation = useNavigation();

  const { exchangePrices } = useContext(AuthContext);

  const generateStyleForInvestIcon = (crypto2) => {
    if (
      cryptoSettings[crypto2] === 'crypto' ||
      cryptoSettings[crypto2] === 'both'
    ) {
      const resp = {
        borderBottomColor: '#348352',
        borderBottomWidth: 3,
      };
      return resp;
    }
    if (cryptoSettings[crypto2] === 'usd') {
      const resp = { borderBottomColor: '#348352', borderBottomWidth: 3 };
      return resp;
    }

    const resp = {};
    return resp;
  };

  const handleOnClick = (selectedCoinName) => {
    //onCryptoClick(selectedCoinName);
    navigation.navigate('CryptoDetails', {
      coinName: selectedCoinName,
      dateRange: dateRange,
    });
  };
  let width = Dimensions.get('screen').width;
  let height = Dimensions.get('screen').height;

  return (
    <TouchableOpacity onPress={() => handleOnClick(coinName)}>
      <Box
        m={10}
        bg={
          cryptoSettings[coinName] === 'crypto' ||
          cryptoSettings[coinName] === 'usd' ||
          cryptoSettings[coinName] === 'both'
            ? '$primary800'
            : 'white'
        }
        py="$4"
        px="$3"
        rounded="$md"
        height={height * 0.12}
        width={width * 0.5}
        maxWidth="100%"
      >
        <HStack space={'3xl'} justifyContent={'space-between'}>
          <Box>
            <CryptoAvatar size={'sm'} crypto={coinName} />
            <Heading
              fontSize="$lg"
              color={
                cryptoSettings[coinName] === 'crypto' ||
                cryptoSettings[coinName] === 'usd' ||
                cryptoSettings[coinName] === 'both'
                  ? 'white'
                  : '$primary800'
              }
            >
              {coinName}
            </Heading>
          </Box>

          {amountEarned > 0.0 && (
            <Box>
              <Heading
                color={
                  cryptoSettings[coinName] === 'crypto' ||
                  cryptoSettings[coinName] === 'usd' ||
                  cryptoSettings[coinName] === 'both'
                    ? 'white'
                    : '$primary800'
                }
                fontSize="$sm"
              >
                ${amountEarned.toFixed(2)} USD
              </Heading>
              <Text
                color={
                  cryptoSettings[coinName] === 'crypto' ||
                  cryptoSettings[coinName] === 'usd' ||
                  cryptoSettings[coinName] === 'both'
                    ? 'white'
                    : '$primary800'
                }
                fontSize={'$sm'}
              >
                Gains
              </Text>
            </Box>
          )}
        </HStack>
      </Box>
    </TouchableOpacity>
  );
};

export default CryptoCard;
