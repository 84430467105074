import React, { useContext, useEffect } from 'react';
import {
  Button,
  ButtonText,
  Heading,
  VStack,
  Toast,
  ToastTitle,
  useToast,
  Center,
  Text,
  Box,
} from '@gluestack-ui/themed';

import { ScrollView, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native';

export default function Privacy({ route }) {
  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <SafeAreaView style={{ flex: 8 }}>
      <ScrollView>
        <Box style={styles.card}>
          <Heading size={'lg'}>
            Privacy Policy for CryptoPilot.app - Mobile Algorithmic Trading
            Application
          </Heading>

          <Text>Effective Date: 2024-03-09</Text>

          <Text>
            Thank you for choosing CryptoPilot.app, a mobile algorithmic trading
            application developed by Javier Tovar. This Privacy Policy is
            designed to inform you about the collection, and use of your
            personal information when you use our application.
          </Text>

          <Heading mt={7} size={'md'}>
            1. Information We Collect:
          </Heading>

          <Heading size={'sm'}>1.1 Personal Information:</Heading>

          <Text>
            When you sign up for CryptoPilot.app, we collect personal
            information. To create an account we collect your email address.
          </Text>

          <Heading size={'sm'}>1.2 Financial Information:</Heading>

          <Text>
            To execute trading activities, CryptoPilot.app requires access to
            either your Bitso or Robinhood account.
          </Text>
          <Text>For automated access:</Text>
          <Text>
            - For Bitso users, we collect your API Key and API Secret. These
            secrets are used to authenticate on the Bitso API.
          </Text>
          <Text>
            - For Robinhood users, we store an expriable Session token. This
            token collected by having the user login into Robinhood and generate
            a new Session token. The token expires within week and the user is
            expected to refresh this Session by login into Robinhood
            periodically. You will receieve notifications if the Robinhood
            Session expired. The token is used to authenticate on the Robinhood
            API.
          </Text>

          <Text>For execution and tracking of trading activities:</Text>
          <Text>
            - CryptoPilot.app requires access to your Bitso or Robinhood account
            balance, transaction history and order creation functionality.
          </Text>

          <Heading mt={7} size={'md'}>
            2. How We Use Your Information:
          </Heading>

          <Heading size={'sm'}>2.1 Trading Activities:</Heading>

          <Text>
            CryptoPilot.app utilizes your financial information to execute
            automated trades on your behalf.
          </Text>
          <Heading size={'sm'}>2.2 Improving User Experience:</Heading>

          <Text>
            We use usage data to enhance and personalize your experience with
            CryptoPilot.app, ensuring that the application meets your needs and
            expectations.
          </Text>
          <Heading size={'sm'}>2.3 Communication:</Heading>

          <Text>
            We use your email information to communicate important updates,
            alerts, notifications, and other relevant information related to
            your use of CryptoPilot.app.
          </Text>

          <Heading mt={7} size={'md'}>
            3. Data Security:
          </Heading>

          <Heading size={'sm'}>3.1 Encryption:</Heading>

          <Text>
            CryptoPilot.app employs industry-standard encryption measures to
            safeguard your personal and financial information during
            transmission and storage.
          </Text>
          <Heading size={'sm'}>3.2 Access Controls:</Heading>

          <Text>
            Access to your data is restricted to authorized personnel and
            systems, ensuring that only necessary individuals have access to
            sensitive information.
          </Text>

          <Heading mt={7} size={'md'}>
            4. Sharing of Information:
          </Heading>

          <Heading size={'sm'}>4.2 Legal Requirements:</Heading>

          <Text>
            We may disclose your information if required by law or in response
            to a valid legal request.
          </Text>

          <Heading mt={7} size={'md'}>
            5. Changes to the Privacy Policy:
          </Heading>

          <Heading size={'sm'}>5.1 Updates:</Heading>

          <Text>
            We may update this Privacy Policy periodically. Any changes will be
            effective upon posting the revised policy in the CryptoPilot.app
            application.
          </Text>

          <Heading mt={7} size={'md'}>
            6. Contact Us:
          </Heading>

          <Text>
            If you have any questions, concerns, or requests regarding your
            privacy or this Privacy Policy, please contact us at
            javilin93@gmail.com.
          </Text>

          <Text>
            By using CryptoPilot.app, you agree to the terms outlined in this
            Privacy Policy.
          </Text>

          <Text>Javier Tovar</Text>
          <Text>3647 Collins Dr.</Text>
          <Text>Doulgasville, GA, 30135</Text>
          <Text>javilin93@gmail.com</Text>
          <Text>+1 404 422 2794</Text>
        </Box>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    //alignItems: 'center',
    // justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
});
