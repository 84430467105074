import React, { useContext, useEffect, useState } from 'react';
import { ScrollView, SafeAreaView, StyleSheet, StatusBar } from 'react-native';
import {
  Button,
  ButtonText,
  Text,
  Box,
  VStack,
  Heading,
} from '@gluestack-ui/themed';
import { AuthContext } from '../AuthProvider';
import Loading from './shared/Loading';
import { dashboardAPI } from '../DashboardAPI';

export default function Terms() {
  const { user, refreshMarketApps } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // init
  }, []);

  const acceptTerms = async () => {
    setLoading(true);
    try {
      await dashboardAPI.acceptTerms(user);
      refreshMarketApps(user);
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <SafeAreaView style={styles.loadingContainer}>
        <Loading />
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView style={styles.scrollView}>
        <Box style={styles.card}>
          <VStack space={5}>
            <Heading size={'lg'}>Terms of Use</Heading>
            <Heading size={'sm'}>Welcome to CryptoPilot.app</Heading>
            <Text style={styles.text}>
              Before you proceed, please carefully read and understand the
              following terms and conditions, as they govern your use of our
              services. By accessing or using CryptoPilot.app, you agree to be
              bound by these Terms of Use. If you do not agree with these terms,
              please refrain from using CryptoPilot.app.
            </Text>
            <Heading size={'sm'}>Risk Acknowledgment</Heading>
            <Text style={styles.text}>
              Financial Investments Inherently Involve Risk
            </Text>
            <Text style={styles.text}>
              Users acknowledge and understand that all financial investments
              carry a degree of risk. The value of investments can fluctuate,
              and there is no guarantee of profit.
            </Text>
            <Heading size={'sm'}>Possibility of Loss</Heading>
            <Text style={styles.text}>
              Users may lose some or all of their invested capital. It is
              important to assess and understand the risks associated with each
              investment before making any decisions.
            </Text>
            <Heading size={'sm'}>Educational Content</Heading>

            <Text style={styles.text}>
              The news feed on CryptoPilot.app is for general informational
              purposes only and should not be considered as financial advice.
            </Text>
            <Heading size={'sm'}>Independent Assessment</Heading>

            <Text style={styles.text}>
              Users are encouraged to independently assess the risks associated
              with specific investments and seek professional advice if needed.
            </Text>
            <Heading size={'sm'}>No Guarantee of Returns</Heading>
            <Text style={styles.text}>
              Past performance is not indicative of future results. Historical
              data does not guarantee future success or profitability.
            </Text>
            <Heading size={'sm'}>Market Conditions</Heading>
            <Text style={styles.text}>
              Users understand that market conditions can change rapidly,
              affecting the performance of their investments.
            </Text>
            <Heading size={'sm'}>Due Diligence</Heading>

            <Text style={styles.text}>
              Users are responsible for conducting thorough due diligence before
              making any investment decisions.
            </Text>

            <Heading size={'sm'}>Account Security</Heading>
            <Text style={styles.text}>
              Users must maintain the security of their accounts and take
              appropriate measures to protect their personal and financial
              information.
            </Text>

            <Heading size={'sm'}>Not Financial Advice</Heading>

            <Text style={styles.text}>
              The news feed on CryptoPilot.app does not constitute financial
              advice. Users are responsible for making their own financial
              decisions based on their individual circumstances.
            </Text>
            <Heading size={'sm'}>Limitation of Liability</Heading>
            <Text style={styles.text}>
              CryptoPilot.app shall not be liable for any losses, damages, or
              expenses incurred by users as a result of their investment
              decisions.
            </Text>

            <Heading size={'sm'}>Force Majeure</Heading>
            <Text style={styles.text}>
              CryptoPilot.app is not responsible for any failure or delay in
              performance resulting from acts beyond its control, including but
              not limited to natural disasters, government actions, technical
              failures, and API rate limits.
            </Text>

            <Heading size={'sm'}>Changes to Terms</Heading>
            <Text style={styles.text}>
              CryptoPilot.app reserves the right to modify these Terms of Use at
              any time. Users will be notified of any changes, and continued use
              of the Platform constitutes acceptance of the modified terms.
            </Text>

            <Heading size={'sm'}>Termination of Services</Heading>

            <Text style={styles.text}>
              CryptoPilot.app reserves the right to terminate or suspend user
              accounts at its discretion, especially in cases of suspected
              fraudulent activities or violations of these Terms of Use.
            </Text>

            <Heading size={'sm'}>Contact</Heading>
            <Text style={styles.text}>
              By using CryptoPilot.app, you acknowledge that you have read,
              understood, and agree to abide by these Terms of Use. If you have
              any questions or concerns, please contact us at
              javilin93@gmail.com . These Terms of Use are effective as of March
              11, 2024.
            </Text>
            <Button onPress={acceptTerms}>
              <ButtonText>Accept</ButtonText>
            </Button>
          </VStack>
        </Box>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: StatusBar.currentHeight || 0,
  },
  scrollView: {
    marginHorizontal: 20,
  },
  card: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
    marginTop: 20,
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    marginBottom: 10,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
