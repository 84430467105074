import React, { useContext, useEffect } from 'react';
import {
  Button,
  ButtonText,
  Heading,
  VStack,
  Toast,
  ToastTitle,
  useToast,
  Center,
  Text,
  Box,
  HStack,
  AccordionItem,
  AccordionHeader,
  AccordionTrigger,
  AccordionTitleText,
  AccordionIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  AccordionContent,
  AccordionContentText,
  Accordion,
  Alert,
  AlertIcon,
  InfoIcon,
  AlertText,
  ButtonGroup,
  Spinner,
  Tooltip,
  TooltipContent,
  TooltipText,
  Pressable,
  Divider,
  Avatar,
  Image,
} from '@gluestack-ui/themed';

import {
  Dimensions,
  FlatList,
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { dashboardAPI } from '../DashboardAPI';
import Loading from './shared/Loading';
import Play from './shared/Play';
import DurationAvatar from './shared/DurationAvatar';
import logo from '../assets/LOGO2.png';
import { AuthContext } from '../AuthProvider';

export default function About({ navigation }) {
  const { user } = useContext(AuthContext);

  const [aboutOrResultsToggle, setAboutOrResultsToggle] =
    React.useState('about');
  const [loading, setLoading] = React.useState(true);
  const [recentPlays, setRecentPlays] = React.useState([]);

  const [totalGainsLoading, setTotalGainsLoading] = React.useState(true);
  const [roiGaugeValue, setRoiGaugeValue] = React.useState(4);
  const [avgDuration, setAvgDuration] = React.useState(4);
  const [totalGainsToolTipState, setTotalGainsToolTipState] =
    React.useState(false);
  const [rankings, setRankings] = React.useState([]);

  const [completedPlaysToolTipState, setCompletedPlaysToolTipState] =
    React.useState(false);
  const [winLossRatioToolTipState, setWinLossRatioToolTipState] =
    React.useState(false);
  const [ROIToolTipState, setROIToolTipState] = React.useState(false);
  const [playDurationToolTipState, setPlayDurationToolTipState] =
    React.useState(false);

  const [closedPlays, setClosedPlays] = React.useState(0);
  const [openedPlays, setOpenedPlays] = React.useState(0);
  const [canceledPlays, setCanceledPlays] = React.useState(0);
  const [gainsTimePeriod, setGainsTimePeriod] = React.useState('ALL_TIME');

  const [totalGains, setTotalGains] = React.useState(0.0);

  let height = Dimensions.get('screen').height;

  useEffect(() => {
    (async () => {
      loadFunds('ALL_TIME');
    })();
  }, []);

  async function loadFunds(timePeriod) {
    setLoading(true);
    dashboardAPI.getAppResultsFeed(timePeriod).then((x) => {
      console.log(x.data);
      if (x.data.recent_plays !== null) {
        setRecentPlays(x.data.recent_plays);
      }
      if (x.data.rankings !== null) {
        setRankings(x.data.rankings);
      }

      setCanceledPlays(x.data.play_summary.canceled_plays);
      setOpenedPlays(x.data.play_summary.opened_plays);
      setClosedPlays(x.data.play_summary.closed_plays);
      setRoiGaugeValue(x.data.play_summary.closed_plays_roi.toFixed(1));
      setAvgDuration(x.data.play_summary.closed_plays_duration);

      setTotalGainsLoading(false);

      let totalGainsNew =
        parseFloat(x.data.total_gains.cash) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.ETH) * x.data.exchange_prices.ETH
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.DOGE) * x.data.exchange_prices.DOGE
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.BTC) * x.data.exchange_prices.BTC
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.BCH) * x.data.exchange_prices.BCH
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.AVAX) * x.data.exchange_prices.AVAX
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.LINK) * x.data.exchange_prices.LINK
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.UNI) * x.data.exchange_prices.UNI
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.ETC) * x.data.exchange_prices.ETC
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.XLM) * x.data.exchange_prices.XLM
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.COMP) * x.data.exchange_prices.COMP
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.AAVE) * x.data.exchange_prices.AAVE
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.XTZ) * x.data.exchange_prices.XTZ
          ).toFixed(2),
        ) +
        parseFloat(
          (
            parseFloat(x.data.total_gains.LTC) * x.data.exchange_prices.LTC
          ).toFixed(2),
        );
      if (isNaN(totalGainsNew)) {
        setTotalGains(0.0);
      } else {
        setTotalGains(totalGainsNew);
      }
      setGainsTimePeriod(timePeriod);

      setLoading(false);
    });
  }
  return (
    <View style={{ height: '100%', width: '100%' }}>
      <ScrollView>
        <Center m={1}>
          <Image
            alt={'CryptoPilot.app Icon'}
            size={'sm'}
            borderRadius="$none"
            source={logo}
          />
          <Heading>CryptoPilot.app</Heading>
          <Text>Automated Crypto Trading Bot</Text>
        </Center>
        <View>
          <ScrollView
            horizontal={true}
            contentContainerStyle={{
              paddingHorizontal: 20,
              paddingBottom: 12,
              paddingTop: 2,
            }}
          >
            <HStack space={'sm'}>
              <Button
                size={'lg'}
                borderRadius="$full"
                onPress={() => setAboutOrResultsToggle('about')}
                variant={aboutOrResultsToggle === 'about' ? 'solid' : 'outline'}
              >
                <ButtonText>About</ButtonText>
              </Button>

              <Button
                size={'lg'}
                borderRadius="$full"
                onPress={() => setAboutOrResultsToggle('results')}
                variant={
                  aboutOrResultsToggle === 'results' ? 'solid' : 'outline'
                }
              >
                <ButtonText>Results</ButtonText>
              </Button>

              <Button
                size={'lg'}
                borderRadius="$full"
                onPress={() => setAboutOrResultsToggle('rankings')}
                variant={
                  aboutOrResultsToggle === 'rankings' ? 'solid' : 'outline'
                }
              >
                <ButtonText>Rankings</ButtonText>
              </Button>
            </HStack>
          </ScrollView>
        </View>

        {aboutOrResultsToggle === 'rankings' && (
          <>
            {rankings
              .filter((r) => r.user !== 'ALL')
              .map((ranking) => (
                <HStack mt={10} ml={10} key={ranking.user}>
                  <Avatar bgColor="$indigo600"></Avatar>
                  <Box>
                    <Heading>{dashboardAPI.hideEmail(ranking.user)}</Heading>

                    <Text>{ranking.crypto_winnings} USD</Text>
                  </Box>
                </HStack>
              ))}

            <HStack space={'4xl'} justifyContent="center" alignItems="center">
              <Button
                onPress={() => loadFunds('1H')}
                variant={gainsTimePeriod === '1H' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText> 1H </ButtonText>
              </Button>
              <Button
                onPress={() => loadFunds('1D')}
                variant={gainsTimePeriod === '1D' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText> 1D </ButtonText>
              </Button>
              <Button
                onPress={() => loadFunds('1W')}
                variant={gainsTimePeriod === '1W' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText> 1W </ButtonText>
              </Button>
              <Button
                onPress={() => loadFunds('1M')}
                variant={gainsTimePeriod === '1M' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText> 1M </ButtonText>
              </Button>
              <Button
                onPress={() => loadFunds('3M')}
                variant={gainsTimePeriod === '3M' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText> 3M </ButtonText>
              </Button>
              <Button
                onPress={() => loadFunds('ALL_TIME')}
                variant={gainsTimePeriod === 'ALL_TIME' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText>ALL TIME</ButtonText>
              </Button>
            </HStack>
          </>
        )}

        {aboutOrResultsToggle === 'results' && (
          <>
            {loading ? (
              <Center>
                <Loading mt={30} />
              </Center>
            ) : (
              <>
                <Heading ml={20}>Plays Completed For Our Users</Heading>
                <FlatList
                  horizontal={Platform.OS === 'ios' ? true : false}
                  w={'$95'}
                  h={'$90'}
                  data={recentPlays}
                  renderItem={({ x, index }) => {
                    return (
                      <Play
                        key={recentPlays[index]['id']}
                        play={recentPlays[index]}
                        viewtoggle={true}
                      />
                    );
                  }}
                  keyExtractor={(x, index) => recentPlays[index]['id']}
                />
              </>
            )}
          </>
        )}

        {aboutOrResultsToggle === 'about' && (
          <ScrollView>
            <Heading ml={20}>Metrics</Heading>
            {loading ? (
              <Loading></Loading>
            ) : (
              <View height={height / 5}>
                <ScrollView
                  horizontal={true}
                  contentContainerStyle={{
                    paddingHorizontal: 15,
                    paddingBottom: 2,
                    paddingTop: 2,
                    marginBottom: 2,
                    height: '80%',
                  }}
                >
                  <Tooltip
                    isOpen={totalGainsToolTipState}
                    placement="top"
                    onClose={() => setTotalGainsToolTipState(false)}
                    onOpen={() => setTotalGainsToolTipState(true)}
                    trigger={(triggerProps) => {
                      return (
                        <TouchableOpacity {...triggerProps}>
                          <Box
                            style={styles.card_metric}
                            borderColor={
                              totalGainsToolTipState === false
                                ? 'black'
                                : '$primary500'
                            }
                          >
                            <Heading
                              size={'sm'}
                              color={
                                totalGainsToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              $
                              {totalGains > 1000
                                ? (totalGains / 1000).toFixed(1) + 'K'
                                : totalGains.toFixed(2)}
                              {' USD'}
                            </Heading>
                            <Text
                              color={
                                totalGainsToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              Gains
                            </Text>

                            {totalGainsLoading && <Spinner size="small" />}
                          </Box>
                        </TouchableOpacity>
                      );
                    }}
                  >
                    <TooltipContent>
                      <TooltipText>Amount won from Plays</TooltipText>
                    </TooltipContent>
                  </Tooltip>

                  <Tooltip
                    isOpen={completedPlaysToolTipState}
                    onClose={() => setCompletedPlaysToolTipState(false)}
                    onOpen={() => setCompletedPlaysToolTipState(true)}
                    placement="top"
                    trigger={(triggerProps) => {
                      return (
                        <TouchableOpacity {...triggerProps}>
                          <Box
                            style={styles.card_metric}
                            borderColor={
                              completedPlaysToolTipState === false
                                ? 'black'
                                : '$primary500'
                            }
                          >
                            <Heading
                              size={'sm'}
                              color={
                                completedPlaysToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              {closedPlays > 1000
                                ? (closedPlays / 1000).toFixed(1) + 'K'
                                : closedPlays.toFixed(0)}{' '}
                            </Heading>
                            <Text
                              color={
                                completedPlaysToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              Plays Completed
                            </Text>
                          </Box>
                        </TouchableOpacity>
                      );
                    }}
                  >
                    <TooltipContent>
                      <TooltipText>Total of Successful Plays</TooltipText>
                    </TooltipContent>
                  </Tooltip>

                  {canceledPlays > 0 && (
                    <Tooltip
                      placement="top"
                      isOpen={winLossRatioToolTipState}
                      onClose={() => setWinLossRatioToolTipState(false)}
                      onOpen={() => setWinLossRatioToolTipState(true)}
                      trigger={(triggerProps) => {
                        return (
                          <TouchableOpacity {...triggerProps}>
                            <Box
                              style={styles.card_metric}
                              borderColor={
                                winLossRatioToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                            >
                              <Heading
                                size={'sm'}
                                color={
                                  winLossRatioToolTipState === false
                                    ? 'black'
                                    : '$primary500'
                                }
                                ml={3}
                                mr={3}
                              >
                                {(closedPlays / canceledPlays).toFixed(2)}
                              </Heading>
                              <Text
                                color={
                                  winLossRatioToolTipState === false
                                    ? 'black'
                                    : '$primary500'
                                }
                                ml={3}
                                mr={3}
                              >
                                W/L Ratio
                              </Text>
                            </Box>
                          </TouchableOpacity>
                        );
                      }}
                    >
                      <TooltipContent>
                        <TooltipText>
                          Successful Plays / Unsuccessful Plays
                        </TooltipText>
                      </TooltipContent>
                    </Tooltip>
                  )}

                  <Tooltip
                    isOpen={ROIToolTipState}
                    placement="top left"
                    onClose={() => setROIToolTipState(false)}
                    onOpen={() => setROIToolTipState(true)}
                    trigger={(triggerProps) => {
                      return (
                        <TouchableOpacity {...triggerProps}>
                          <Box
                            style={styles.card_metric}
                            borderColor={
                              ROIToolTipState === false
                                ? 'black'
                                : '$primary500'
                            }
                          >
                            <Heading
                              size={'sm'}
                              color={
                                ROIToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              %{roiGaugeValue}
                            </Heading>
                            <Text
                              color={
                                ROIToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              ROI Per Play
                            </Text>
                          </Box>
                        </TouchableOpacity>
                      );
                    }}
                  >
                    <TooltipContent>
                      <TooltipText>Return On Investment</TooltipText>
                    </TooltipContent>
                  </Tooltip>

                  <Tooltip
                    placement="top left"
                    isOpen={playDurationToolTipState}
                    onClose={() => setPlayDurationToolTipState(false)}
                    onOpen={() => setPlayDurationToolTipState(true)}
                    trigger={(triggerProps) => {
                      return (
                        <TouchableOpacity {...triggerProps}>
                          <Box
                            style={styles.card_metric}
                            borderColor={
                              playDurationToolTipState === false
                                ? 'black'
                                : '$primary500'
                            }
                          >
                            <Center>
                              <DurationAvatar
                                ml={3}
                                mr={3}
                                color={
                                  playDurationToolTipState === false
                                    ? 'black'
                                    : '$primary500'
                                }
                                horizontal
                                duration={avgDuration}
                              />
                            </Center>

                            <Text
                              color={
                                playDurationToolTipState === false
                                  ? 'black'
                                  : '$primary500'
                              }
                              ml={3}
                              mr={3}
                            >
                              Avg Play Duration
                            </Text>
                          </Box>
                        </TouchableOpacity>
                      );
                    }}
                  >
                    <TooltipContent>
                      <TooltipText>Play Completion Time</TooltipText>
                    </TooltipContent>
                  </Tooltip>
                </ScrollView>
              </View>
            )}
            <HStack space={'4xl'} justifyContent="center" alignItems="center">
              <Button
                onPress={() => loadFunds('1H')}
                variant={gainsTimePeriod === '1H' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText> 1H </ButtonText>
              </Button>
              <Button
                onPress={() => loadFunds('1D')}
                variant={gainsTimePeriod === '1D' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText> 1D </ButtonText>
              </Button>
              <Button
                onPress={() => loadFunds('1W')}
                variant={gainsTimePeriod === '1W' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText> 1W </ButtonText>
              </Button>
              <Button
                onPress={() => loadFunds('1M')}
                variant={gainsTimePeriod === '1M' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText> 1M </ButtonText>
              </Button>
              <Button
                onPress={() => loadFunds('3M')}
                variant={gainsTimePeriod === '3M' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText> 3M </ButtonText>
              </Button>
              <Button
                onPress={() => loadFunds('ALL_TIME')}
                variant={gainsTimePeriod === 'ALL_TIME' ? 'solid' : 'link'}
                size={'xs'}
              >
                <ButtonText>ALL TIME</ButtonText>
              </Button>
            </HStack>

            <Accordion
              m="$5"
              width="90%"
              size="md"
              variant="filled"
              type="single"
              isCollapsible={true}
              isDisabled={false}
            >
              <AccordionItem value="a">
                <AccordionHeader>
                  <AccordionTrigger>
                    {({ isExpanded }) => {
                      return (
                        <>
                          <AccordionTitleText>
                            What is CryptoPilot.app?
                          </AccordionTitleText>
                          {isExpanded ? (
                            <AccordionIcon as={ChevronUpIcon} ml="$3" />
                          ) : (
                            <AccordionIcon as={ChevronDownIcon} ml="$3" />
                          )}
                        </>
                      );
                    }}
                  </AccordionTrigger>
                </AccordionHeader>
                <AccordionContent>
                  <AccordionContentText>
                    Automated trading bot for cryptocurrencies. Connects with
                    your crypto broker (Robinhood, Bitso, or Binance) to place
                    automated buy and sell orders.
                  </AccordionContentText>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="c">
                <AccordionHeader>
                  <AccordionTrigger>
                    {({ isExpanded }) => {
                      return (
                        <>
                          <AccordionTitleText>
                            How does it work?
                          </AccordionTitleText>
                          {isExpanded ? (
                            <AccordionIcon as={ChevronUpIcon} ml="$3" />
                          ) : (
                            <AccordionIcon as={ChevronDownIcon} ml="$3" />
                          )}
                        </>
                      );
                    }}
                  </AccordionTrigger>
                </AccordionHeader>
                <AccordionContent>
                  <AccordionContentText>
                    CryptoPilot.app interacts with crypto brokers through APIs
                    You provide CryptoPilot.app with your API Key.
                    CryptoPilot.app uses this info to place orders on your
                    behalf. Step By Step guides for each broker are listed below
                  </AccordionContentText>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="d">
                <AccordionHeader>
                  <AccordionTrigger>
                    {({ isExpanded }) => {
                      return (
                        <>
                          <AccordionTitleText>Strategy</AccordionTitleText>
                          {isExpanded ? (
                            <AccordionIcon as={ChevronUpIcon} ml="$3" />
                          ) : (
                            <AccordionIcon as={ChevronDownIcon} ml="$3" />
                          )}
                        </>
                      );
                    }}
                  </AccordionTrigger>
                </AccordionHeader>
                <AccordionContent>
                  <AccordionContentText>
                    CryptoPilot.app breaks up your investment into $1 USD funds.
                    CryptoPilot.app continuously scans the market for
                    opportunities to invest these $1 USD funds.
                  </AccordionContentText>
                </AccordionContent>
              </AccordionItem>
            </Accordion>

            <Center>
              <ButtonGroup mb={10} mt={10}>
                <Button
                  onPress={() => Linking.openURL('https://discord.gg/kbx4jugR')}
                >
                  <ButtonText>Join Our Discord</ButtonText>
                </Button>
              </ButtonGroup>
              <Heading>Step By Step Guides</Heading>
              <ButtonGroup>
                <Button
                  onPress={() => navigation.navigate('RobinhoodGuide')}
                  variant={'outline'}
                >
                  <ButtonText>Robinhood</ButtonText>
                </Button>

                <Button
                  onPress={() => navigation.navigate('BinanceGuide')}
                  variant={'outline'}
                >
                  <ButtonText>Binance</ButtonText>
                </Button>
                <Button
                  onPress={() => navigation.navigate('BitsoGuide')}
                  variant={'outline'}
                >
                  <ButtonText>Bitso</ButtonText>
                </Button>
              </ButtonGroup>
            </Center>
          </ScrollView>
        )}
        {user === false && (
          <Center style={styles.signupCard}>
            <Text m={1}>Interested?</Text>
            <ButtonGroup>
              <Button onPress={() => navigation.navigate('CryptoPilotSignUp')}>
                <ButtonText>Create Account</ButtonText>
              </Button>
            </ButtonGroup>
          </Center>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  card_metric: {
    // flex: 1,
    //alignItems: 'center',
    // justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 5,
    marginLeft: 5,
    marginTop: 15,
    marginBottom: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 2,
    backgroundColor: '#fff',
    height: '90%',
  },
  signupCard: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 20,
    paddingBottom: 10,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
});
