import React, { useContext, useEffect } from 'react';
import {
  Center,
  Heading,
  Box,
  Button,
  Avatar,
  VStack,
  HStack,
  RadioGroup,
  Radio,
  RadioLabel,
  RadioIndicator,
  RadioIcon,
  CircleIcon,
  Actionsheet,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetSectionHeaderText,
  Alert,
  AlertIcon,
  AlertText,
  ButtonText,
  CheckCircleIcon,
  Icon,
  AtSignIcon,
  Text,
  ActionsheetItem,
  ActionsheetItemText,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputSlot,
  InputIcon,
  InputField,
  Image,
  useToast,
  Toast,
  ToastTitle,
  Divider,
  ButtonIcon,
} from '@gluestack-ui/themed';

import { AuthContext } from '../AuthProvider';
import { createUserWithEmailAndPassword } from 'firebase/auth';

import { SafeAreaView, StyleSheet } from 'react-native';
import Loading from './shared/Loading';
import firebaseUtils from '../Firebase';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const logo = require('../assets/LOGO2.png');

export default function CryptoPilotSignUp({ route, navigation }) {
  const { user, marketApps, login, marketAppsLoading, refreshMarketApps } =
    useContext(AuthContext);

  const [robinhoodPassword, setRobinhoodPassword] = React.useState('');
  const [robinhoodPassword2, setRobinhoodPassword2] = React.useState('');
  const [robinhoodEmail, setRobinhoodEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');

  useEffect(() => {}, []);
  const toast = useToast();

  const googleLogin = () => {
    login();
    navigation.navigate('Home');
  };

  const createUser = () => {
    setLoading(true);
    setErrorMsg('');
    createUserWithEmailAndPassword(
      firebaseUtils('auth'),
      robinhoodEmail,
      robinhoodPassword,
    )
      .then((userCredential) => {
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="info" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Account Created!</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });

        navigation.navigate('Home');
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        setErrorMsg(e);
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable To Create Account</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      });
  };

  return (
    <SafeAreaView>
      {loading ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <Box>
          <Center style={styles.card}>
            <Image
              alt={'CryptoPilot.app Icon'}
              size={'md'}
              borderRadius="$none"
              source={logo}
            />
            <Heading>CryptoPilot.app</Heading>
            <Text>Automated Crypto Trading</Text>

            <VStack w="$full" p={20}>
              <FormControl mt={36}>
                <FormControlLabel>
                  <FormControlLabelText>Email</FormControlLabelText>
                </FormControlLabel>
                <Input
                  aria-label={'Crypto Account Email'}
                  size={'lg'}
                  w="$full"
                >
                  <InputField
                    value={robinhoodEmail}
                    onChangeText={setRobinhoodEmail}
                    variant="rounded"
                  />
                </Input>
                <FormControlLabel>
                  <FormControlLabelText>Password</FormControlLabelText>
                </FormControlLabel>
                <Input
                  aria-label={'CryptoPilot.app Account Password'}
                  size={'lg'}
                  w="$full"
                >
                  <InputField
                    value={robinhoodPassword}
                    onChangeText={setRobinhoodPassword}
                    type={'password'}
                    variant="rounded"
                  />
                </Input>
                <FormControlLabel>
                  <FormControlLabelText>Confirm Password</FormControlLabelText>
                </FormControlLabel>
                <Input
                  aria-label={'Confirm CryptoPilot.app Account Password'}
                  w="$full"
                >
                  <InputField
                    value={robinhoodPassword2}
                    onChangeText={setRobinhoodPassword2}
                    type={'password'}
                    variant="rounded"
                  />
                </Input>

                {errorMsg != '' && <Text>{errorMsg.toString()}</Text>}
                {robinhoodPassword !== robinhoodPassword2 &&
                  robinhoodPassword2 !== '' &&
                  robinhoodPassword2.length > 3 && (
                    <Text>Confirmed password does not match password</Text>
                  )}

                <Button
                  isDisabled={
                    robinhoodPassword !== robinhoodPassword2 &&
                    robinhoodPassword !== ''
                  }
                  onPress={() => createUser()}
                  mt={20}
                >
                  <ButtonText>Create Account</ButtonText>
                </Button>
              </FormControl>
            </VStack>
            <HStack space="sm" mb="$4" alignItems="center">
              <Divider w={80} />
              <Text size="sm">OR</Text>
              <Divider w={80} />
            </HStack>
            <Button
              title="Sign in with Google"
              onPress={() => googleLogin()}
              buttonStyle={styles.googleSignInButton}
              textStyle={styles.buttonText}
            >
              <ButtonIcon style={styles.buttonIcon}>
                <FontAwesomeIcon icon={faGoogle} color="white" size={15} />
              </ButtonIcon>
              <ButtonText>Sign in with Google</ButtonText>
            </Button>
          </Center>
        </Box>
      )}
      <Center style={styles.card}>
        <Text>Have an account?</Text>
        <Button onPress={() => navigation.navigate('Home')} variant={'link'}>
          <ButtonText>Log in</ButtonText>
        </Button>
      </Center>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
});
