import React, { useContext, useEffect } from 'react';
import {
  Box,
  Button,
  ButtonText,
  Center,
  HStack,
  Heading,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetSectionHeaderText,
  ActionsheetItemText,
  Actionsheet,
  ActionsheetItem,
  Text,
  ButtonGroup,
} from '@gluestack-ui/themed';

import { dashboardAPI } from '../DashboardAPI';

import { AuthContext } from '../AuthProvider';
import CryptoAvatar from './shared/CryptoAvatar';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { LineChart } from 'react-native-wagmi-charts';
import Loading from './shared/Loading';
import RoiSpeedometer from './shared/RoiSpeedometer';

export default function CreatePlay({ navigation, route }) {
  const { user, marketApps } = useContext(AuthContext);

  const [crypto, setCrypto] = React.useState('BTC');
  const [chartData, setChartData] = React.useState(null);
  const [dateRange, setDateRange] = React.useState('3M');
  const [playType, setPlayType] = React.useState('BULL');
  const [currentPrice, setCurrentPrice] = React.useState(44000);
  const [exchangePrices, setExchangePrices] = React.useState(null);

  const [min, setMin] = React.useState(null);
  const [max, setMax] = React.useState(null);
  const [funds, setFunds] = React.useState([]);
  const [fund, setFund] = React.useState(null);
  const [fundLabel, setFundLabel] = React.useState('');

  const [startMultiplier, setStartMultiplier] = React.useState(0.0);
  const [endMultiplier, setEndMultiplier] = React.useState(0.05);

  const [showCryptoSelectionSheet, setShowCryptoSelectionSheet] =
    React.useState(false);
  const [showStartPriceSelectionSheet, setShowStartPriceSelectionSheet] =
    React.useState(false);
  const [showFundSelectionSheet, setShowFundSelectionSheet] =
    React.useState(false);
  const [showEndtPriceSelectionSheet, setShowEndPriceSelectionSheet] =
    React.useState(false);

  const [showMarketSelectionSheet, setShowMarketSelectionSheet] =
    React.useState(false);
  const [showPlayTypeSelectionSheet, setShowPlayTypeSelectionSheet] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [marketApp, setMarketApp] = React.useState(
    marketApps.length > 0 ? marketApps[0] : 'robinhood',
  );

  const [startingAmountString, setStartingAmountString] = React.useState('');
  const [endingAmountString, setEndingAmountString] = React.useState('');
  const [amountInFiatCurrency, setAmountInFiatCurrency] = React.useState(null);

  const handleClosePlayTypeSelection = (asset2) => {
    setPlayType(asset2);
    setShowPlayTypeSelectionSheet(false);
  };

  const handleFundSelection = (asset2) => {
    setFund(asset2);
    setFundLabel(generateFundLabel(asset2));

    setShowFundSelectionSheet(false);
  };
  const handleCloseStartPriceSelection = (asset2) => {
    setStartMultiplier(asset2);
    setShowStartPriceSelectionSheet(false);
  };
  const handleCloseEndPriceSelection = (asset2) => {
    setEndMultiplier(asset2);
    setShowEndPriceSelectionSheet(false);
  };

  const handleCloseCryptoSelection = (asset2) => {
    setCrypto(asset2);
    setShowCryptoSelectionSheet(false);
    handleTimePeriodSelect(dateRange, asset2);
  };

  const handleCloseMarketSelection = (asset2) => {
    setMarketApp(asset2);
    setShowMarketSelectionSheet(false);
  };

  const calculateRoi = (playType2, endMultiplier2, startMultiplier2) => {
    if (playType2 === 'BULL') {
      return (1 + endMultiplier2) / (1 - startMultiplier2) - 1;
    } else {
      return (1 + startMultiplier2) / (1 - endMultiplier2) - 1;
    }
  };

  const makePlay = () => {
    setIsLoading(true);

    const value = {
      asset: crypto,
      play_type: playType,
      fund_id: fund.id,
      amount_in_fiat_currency: amountInFiatCurrency,
      starting_price:
        startMultiplier === 0.0 ? 'market_price' : startMultiplier,
      ending_price: endMultiplier,
      market_app: marketApp,
    };

    dashboardAPI.makePlay(user, value).then((x) => {
      navigation.navigate('Home');
    });
  };

  const generateEndingAmountString = (fund2, roi) => {
    if (fund2.amount_in_usd && fund2.asset === 'cash') {
      return '$' + (fund2.amount_in_usd * 1 + roi).toFixed(2) + ' USD';
    } else if (fund2.amount_in_cop && fund2.asset === 'cash') {
      return '$' + (fund2.amount_in_cop * 1 + roi).toFixed(2) + ' COP';
    } else if (fund2.amount_in_mxn && fund2.asset === 'cash') {
      return '$' + (fund2.amount_in_mxn * 1 + roi).toFixed(2) + ' MXN';
    } else if (fund2.amount_in_crypto) {
      return (
        '$' +
        (
          parseFloat(fund2.amount_in_crypto) *
          exchangePrices[fund2.asset] *
          (1 + roi)
        ).toFixed(2) +
        ' USD'
      );
    } else if (fund2.amunt_in_crypto) {
      return (
        '$' +
        (
          parseFloat(fund2.amunt_in_crypto) *
          exchangePrices[fund2.asset] *
          (1 + roi)
        ).toFixed(2) +
        ' USD'
      );
    }
    return '';
  };

  const generateStartingAmountString = (fund2) => {
    if (fund2.amount_in_usd && fund2.asset === 'cash') {
      return '$' + fund2.amount_in_usd.toFixed(2) + ' USD';
    } else if (fund2.amount_in_cop && fund2.asset === 'cash') {
      return '$' + fund2.amount_in_cop.toFixed(2) + ' COP';
    } else if (fund2.amount_in_mxn && fund2.asset === 'cash') {
      return '$' + fund2.amount_in_mxn.toFixed(2) + ' MXN';
    } else if (fund2.amount_in_crypto) {
      if (exchangePrices === null) {
        return '';
      }
      return (
        '$' +
        (
          parseFloat(fund2.amount_in_crypto) * exchangePrices[fund2.asset]
        ).toFixed(2) +
        ' USD'
      );
    }
    return '';
  };

  const generateFundLabel = (fund2) => {
    if (fund2.amount_in_usd && fund2.asset === 'cash') {
      return '$' + fund2.amount_in_usd.toFixed(2) + ' USD';
    } else if (fund2.amount_in_cop && fund2.asset === 'cash') {
      return '$' + fund2.amount_in_cop.toFixed(2) + ' COP';
    } else if (fund2.amount_in_mxn && fund2.asset === 'cash') {
      return '$' + fund2.amount_in_mxn.toFixed(2) + ' MXN';
    } else if (fund2.amount_in_crypto) {
      return parseFloat(fund2.amount_in_crypto).toFixed(6) + ' ' + fund2.asset;
    }
    return '';
  };

  useEffect(() => {
    (async () => {
      ///TODO
      handleTimePeriodSelect('3M', crypto);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      ///TODO
      setEndingAmountString(
        generateEndingAmountString(
          fund,
          calculateRoi(playType, startMultiplier, endMultiplier),
        ),
      );
      setStartingAmountString(generateStartingAmountString(fund));
    })();
  }, [playType, startMultiplier, endMultiplier, fund]);

  useEffect(() => {
    async function getFunds() {
      console.log('refreshed???');

      const res2 = await dashboardAPI.getFunds(
        // profile.email,
        user,
        marketApp,
        playType === 'BULL' ? 'cash' : crypto,
      );
      const fundsData = res2.data;

      setFunds(fundsData);
      if (fundsData.length > 0) {
        setFund(fundsData[fundsData.length - 1]);
        setFundLabel(generateFundLabel(fundsData[fundsData.length - 1]));
        setStartingAmountString(
          generateStartingAmountString(fundsData[fundsData.length - 1]),
        );
        setEndingAmountString(
          generateEndingAmountString(
            fundsData[fundsData.length - 1],
            calculateRoi(playType, startMultiplier, endMultiplier),
          ),
        );
      }
    }

    getFunds();
  }, [user.accessToken, marketApp, playType, crypto, exchangePrices]);

  function handleTimePeriodSelect(dateRangee, crypto_symbol) {
    dashboardAPI.getCharts(dateRangee, crypto_symbol, 'usd').then((x2) => {
      let newData = [];
      let newMax = null;
      let newMin = null;
      for (let i = 0; i < x2.data.candles.length; i++) {
        const obj = {
          timestamp: new Date(x2.data.candles[i].begins_at),
          value: x2.data.candles[i].close_price,
          price: x2.data.candles[i].close_price,
        };
        newData.push(obj);

        if (newMin === null || x2.data.candles[i].price < newMin) {
          newMin = x2.data.candles[i].price;
        }

        if (newMax === null || x2.data.candles[i].price > newMax) {
          newMax = x2.data.candles[i].price;
        }
      }
      setMax(newMax);
      setMin(newMin);
      setChartData(newData);
      setDateRange(dateRangee);
      setExchangePrices(x2.data.exchange_prices);
      setCurrentPrice(x2.data.exchange_prices[crypto_symbol]);
    });
  }

  let width = Dimensions.get('window').width;
  let height = Dimensions.get('window').height;

  return (
    <>
      {isLoading ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <>
          <Heading>Create Play</Heading>
          <View
            styles={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <ScrollView
              horizontal={true}
              contentContainerStyle={{ paddingHorizontal: 40 }}
            >
              <Center>
                <Text>Crypto</Text>
                <Button
                  w={'200px'}
                  variant={'outline'}
                  action={'primary'}
                  onPress={() => setShowCryptoSelectionSheet(true)}
                >
                  <CryptoAvatar size={'sm'} crypto={crypto} />
                  <ButtonText>{crypto}</ButtonText>
                </Button>
              </Center>
              {/*<Center>*/}
              {/*  <Text>Fund Type</Text>*/}
              {/*  <Button w={ fundType === 'ALL' ? '200px' : '300px'} onPress={() => setShowFundTypeSelectionSheet(true)} variant={'outline'} action={'primary'}   ><ButtonText>{typeText[fundType]}</ButtonText></Button>*/}
              {/*</Center>*/}
              {/*<Text> </Text>*/}
              <Center mt={20}>
                <Text>Play</Text>
                <Button
                  onPress={() => setShowPlayTypeSelectionSheet(true)}
                  variant={'outline'}
                  action={'primary'}
                >
                  <ButtonText>{playType}</ButtonText>
                </Button>
              </Center>
              {marketApps.length > 1 && (
                <Center>
                  <Text>Market</Text>
                  <Button
                    w={'200px'}
                    variant={'outline'}
                    action={'primary'}
                    onPress={() => setShowMarketSelectionSheet(true)}
                  >
                    <ButtonText>{marketApp}</ButtonText>
                  </Button>
                </Center>
              )}
            </ScrollView>
          </View>

          <View style={{ height: '45%', width: '95%' }}>
            <ScrollView horizontal={true}>
              {chartData !== null ? (
                <Box>
                  <LineChart.Provider data={chartData}>
                    <HStack>
                      <LineChart.PriceText
                        style={{
                          backgroundColor: 'white',
                          borderRadius: 4,
                          color: 'black',
                          fontSize: 18,
                          padding: 4,
                          width: 320,
                        }}
                        precision={4}
                        format={(x) => {
                          'worklet';
                          //const formattedDate = yourOwnFormatValueFn(value);
                          return (
                            'CURRENT PRICE: ' + currentPrice.toFixed(4) + ' USD'
                          );
                        }}
                      ></LineChart.PriceText>
                    </HStack>
                    <Center>
                      <LineChart width={width * 0.7} height={height / 3}>
                        <LineChart.Path color={'black'}>
                          <LineChart.Gradient
                            color={playType === 'BULL' ? 'green' : 'red'}
                          />
                          {startMultiplier !== null && (
                            <LineChart.HorizontalLine
                              color={playType === 'BULL' ? 'green' : 'red'}
                              at={{
                                value:
                                  playType === 'BULL'
                                    ? currentPrice * (1 - startMultiplier)
                                    : currentPrice * (1 + startMultiplier),
                              }}
                            />
                          )}
                          \
                          {endMultiplier !== null && (
                            <LineChart.HorizontalLine
                              color={playType === 'BEAR' ? 'green' : 'red'}
                              at={{
                                value:
                                  playType === 'BULL'
                                    ? currentPrice * (1 + endMultiplier)
                                    : currentPrice * (1 - endMultiplier),
                              }}
                            />
                          )}
                        </LineChart.Path>
                      </LineChart>
                    </Center>

                    <HStack
                      space={'4xl'}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button
                        onPress={() => handleTimePeriodSelect('1D', crypto)}
                        variant={dateRange === '1D' ? 'solid' : 'link'}
                        size={'xs'}
                      >
                        <ButtonText>1D</ButtonText>
                      </Button>
                      <Button
                        onPress={() => handleTimePeriodSelect('1W', crypto)}
                        variant={dateRange === '1W' ? 'solid' : 'link'}
                        size={'xs'}
                      >
                        <ButtonText>1W</ButtonText>
                      </Button>
                      <Button
                        onPress={() => handleTimePeriodSelect('1M', crypto)}
                        variant={dateRange === '1M' ? 'solid' : 'link'}
                        size={'xs'}
                      >
                        <ButtonText>1M</ButtonText>
                      </Button>
                      <Button
                        onPress={() => handleTimePeriodSelect('3M', crypto)}
                        variant={dateRange === '3M' ? 'solid' : 'link'}
                        size={'xs'}
                      >
                        <ButtonText>3M</ButtonText>
                      </Button>
                    </HStack>
                  </LineChart.Provider>
                </Box>
              ) : (
                <Center>
                  <Loading />
                </Center>
              )}
              <Center style={styles.card}>
                <RoiSpeedometer
                  roigaugevalue={parseFloat(
                    (
                      calculateRoi(playType, startMultiplier, endMultiplier) *
                      100
                    ).toFixed(1),
                  )}
                />
              </Center>
            </ScrollView>
          </View>
          {funds.length > 0 ? (
            <Center>
              <Text>Fund</Text>
              <Button
                variant={'outline'}
                onPress={() => setShowFundSelectionSheet(true)}
                size={'xs'}
              >
                <ButtonText>{fundLabel}</ButtonText>
              </Button>

              <Center>
                <HStack space={'2xl'}>
                  <Center>
                    <Heading>
                      {playType === 'BULL' ? 'BUY' : 'SELL'}{' '}
                      {startingAmountString}
                    </Heading>
                    <Text>@</Text>
                    <Button
                      onPress={() => setShowStartPriceSelectionSheet(true)}
                      size={'xs'}
                      variant={'outline'}
                    >
                      <ButtonText>
                        {' '}
                        $
                        {(playType === 'BULL'
                          ? currentPrice * (1 - startMultiplier)
                          : currentPrice * (1 + startMultiplier)
                        ).toFixed(2)}{' '}
                        USD
                      </ButtonText>
                    </Button>
                  </Center>
                  <Center>
                    <Heading>{'>>'}</Heading>
                  </Center>
                  <Center>
                    <Heading>
                      {playType === 'BULL' ? 'SELL' : 'BUY'}{' '}
                      {endingAmountString}
                    </Heading>
                    <Text>@</Text>
                    <Button
                      onPress={() => setShowEndPriceSelectionSheet(true)}
                      size={'xs'}
                      variant={'outline'}
                    >
                      <ButtonText>
                        {' '}
                        $
                        {(playType === 'BULL'
                          ? currentPrice * (1 + endMultiplier)
                          : currentPrice * (1 - endMultiplier)
                        ).toFixed(2)}{' '}
                        USD
                      </ButtonText>
                    </Button>
                  </Center>
                </HStack>

                <ButtonGroup mt={10}>
                  <Button
                    variant={'outline'}
                    action={'secondary'}
                    onPress={() => navigation.navigate('Home')}
                  >
                    <ButtonText>Cancel</ButtonText>
                  </Button>
                  <Button onPress={() => makePlay()}>
                    <ButtonText>Create Play</ButtonText>
                  </Button>
                </ButtonGroup>
              </Center>
            </Center>
          ) : (
            <Center>
              <Heading>No Funds For This Kind Of Play</Heading>
              <ButtonGroup>
                <Button
                  variant={'outline'}
                  action={'secondary'}
                  onPress={() => navigation.navigate('Home')}
                >
                  <ButtonText>Cancel</ButtonText>
                </Button>
                <Button>
                  <ButtonText>Add Funds</ButtonText>
                </Button>
              </ButtonGroup>
            </Center>
          )}
        </>
      )}

      <Actionsheet
        isOpen={showEndtPriceSelectionSheet}
        onClose={() =>
          setShowEndPriceSelectionSheet(!showEndtPriceSelectionSheet)
        }
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>
            Ending Price
          </ActionsheetSectionHeaderText>

          <View style={{ height: '80%', width: '95%' }}>
            <ScrollView>
              <ActionsheetItem
                onPress={() => handleCloseEndPriceSelection(0.01)}
              >
                <ActionsheetItemText>
                  %1 {playType === 'BEAR' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BEAR'
                    ? (currentPrice * 0.99).toFixed(4)
                    : (currentPrice * 1.01).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseEndPriceSelection(0.02)}
              >
                <ActionsheetItemText>
                  %2 {playType === 'BEAR' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BEAR'
                    ? (currentPrice * 0.98).toFixed(4)
                    : (currentPrice * 1.02).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseEndPriceSelection(0.03)}
              >
                <ActionsheetItemText>
                  %3 {playType === 'BEAR' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BEAR'
                    ? (currentPrice * 0.97).toFixed(4)
                    : (currentPrice * 1.03).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseEndPriceSelection(0.05)}
              >
                <ActionsheetItemText>
                  %5 {playType === 'BEAR' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BEAR'
                    ? (currentPrice * 0.95).toFixed(4)
                    : (currentPrice * 1.05).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseEndPriceSelection(0.07)}
              >
                <ActionsheetItemText>
                  %7 {playType === 'BEAR' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BEAR'
                    ? (currentPrice * 0.93).toFixed(4)
                    : (currentPrice * 1.07).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseEndPriceSelection(0.1)}
              >
                <ActionsheetItemText>
                  %10 {playType === 'BEAR' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BEAR'
                    ? (currentPrice * 0.9).toFixed(4)
                    : (currentPrice * 1.1).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>
            </ScrollView>
          </View>
        </ActionsheetContent>
      </Actionsheet>

      <Actionsheet
        isOpen={showStartPriceSelectionSheet}
        onClose={() =>
          setShowStartPriceSelectionSheet(!showStartPriceSelectionSheet)
        }
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>
            Starting Price
          </ActionsheetSectionHeaderText>

          <View style={{ height: '80%', width: '95%' }}>
            <ScrollView>
              <ActionsheetItem
                onPress={() => handleCloseStartPriceSelection(0.0)}
              >
                <ActionsheetItemText>
                  Market Price - {currentPrice}
                </ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseStartPriceSelection(0.01)}
              >
                <ActionsheetItemText>
                  %1 {playType === 'BULL' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BULL'
                    ? (currentPrice * 0.99).toFixed(4)
                    : (currentPrice * 1.01).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseStartPriceSelection(0.02)}
              >
                <ActionsheetItemText>
                  %2 {playType === 'BULL' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BULL'
                    ? (currentPrice * 0.98).toFixed(4)
                    : (currentPrice * 1.02).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseStartPriceSelection(0.03)}
              >
                <ActionsheetItemText>
                  %3 {playType === 'BULL' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BULL'
                    ? (currentPrice * 0.97).toFixed(4)
                    : (currentPrice * 1.03).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseStartPriceSelection(0.05)}
              >
                <ActionsheetItemText>
                  %5 {playType === 'BULL' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BULL'
                    ? (currentPrice * 0.95).toFixed(4)
                    : (currentPrice * 1.05).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseStartPriceSelection(0.07)}
              >
                <ActionsheetItemText>
                  %7 {playType === 'BULL' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BULL'
                    ? (currentPrice * 0.93).toFixed(4)
                    : (currentPrice * 1.07).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseStartPriceSelection(0.1)}
              >
                <ActionsheetItemText>
                  %10 {playType === 'BULL' ? 'Below' : 'Above'} Market -{' '}
                  {playType === 'BULL'
                    ? (currentPrice * 0.9).toFixed(4)
                    : (currentPrice * 1.1).toFixed(4)}
                </ActionsheetItemText>
              </ActionsheetItem>
            </ScrollView>
          </View>
        </ActionsheetContent>
      </Actionsheet>

      <Actionsheet
        isOpen={showFundSelectionSheet}
        onClose={() => setShowFundSelectionSheet(!showFundSelectionSheet)}
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>Funds</ActionsheetSectionHeaderText>

          <View style={{ height: '80%', width: '95%' }}>
            <ScrollView>
              {funds.map((fund) => {
                let label = generateFundLabel(fund);

                return (
                  <ActionsheetItem
                    onPress={() => handleFundSelection(fund)}
                    key={fund.id}
                    value={fund.id}
                  >
                    <Text>{label}</Text>
                  </ActionsheetItem>
                );
              })}
            </ScrollView>
          </View>
        </ActionsheetContent>
      </Actionsheet>

      <Actionsheet
        isOpen={showCryptoSelectionSheet}
        onClose={() => setShowCryptoSelectionSheet(!showCryptoSelectionSheet)}
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>Cryptos</ActionsheetSectionHeaderText>

          <View style={{ height: '80%', width: '95%' }}>
            <ScrollView>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('BTC')}
              >
                <CryptoAvatar crypto={'BTC'} />
                <ActionsheetItemText>BTC</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('ETH')}
              >
                <CryptoAvatar crypto={'ETH'} />
                <ActionsheetItemText>ETH</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('DOGE')}
              >
                <CryptoAvatar crypto={'DOGE'} />
                <ActionsheetItemText>DOGE</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('AVAX')}
              >
                <CryptoAvatar crypto={'AVAX'} />
                <ActionsheetItemText>AVAX</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('LINK')}
              >
                <CryptoAvatar crypto={'LINK'} />
                <ActionsheetItemText>LINK</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('UNI')}
              >
                <CryptoAvatar crypto={'UNI'} />
                <ActionsheetItemText>UNI</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('XLM')}
              >
                <CryptoAvatar crypto={'XLM'} />
                <ActionsheetItemText>XLM</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('XTZ')}
              >
                <CryptoAvatar crypto={'XTZ'} />
                <ActionsheetItemText>XTZ</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('COMP')}
              >
                <CryptoAvatar crypto={'COMP'} />
                <ActionsheetItemText>COMP</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('AAVE')}
              >
                <CryptoAvatar crypto={'AAVE'} />
                <ActionsheetItemText>AAVE</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('LTC')}
              >
                <CryptoAvatar crypto={'LTC'} />
                <ActionsheetItemText>LTC</ActionsheetItemText>
              </ActionsheetItem>

              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('ADA')}
              >
                <CryptoAvatar crypto={'ADA'} />
                <ActionsheetItemText>ADA</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('SHIB')}
              >
                <CryptoAvatar crypto={'SHIB'} />
                <ActionsheetItemText>SHIB</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('MATIC')}
              >
                <CryptoAvatar crypto={'MATIC'} />
                <ActionsheetItemText>MATIC</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('SOL')}
              >
                <CryptoAvatar crypto={'SOL'} />
                <ActionsheetItemText>SOL</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('XRP')}
              >
                <CryptoAvatar crypto={'XRP'} />
                <ActionsheetItemText>XRP</ActionsheetItemText>
              </ActionsheetItem>
              <ActionsheetItem
                onPress={() => handleCloseCryptoSelection('DOT')}
              >
                <CryptoAvatar crypto={'DOT'} />
                <ActionsheetItemText>DOT</ActionsheetItemText>
              </ActionsheetItem>
            </ScrollView>
          </View>
        </ActionsheetContent>
      </Actionsheet>

      <Actionsheet
        isOpen={showMarketSelectionSheet}
        onClose={() => setShowMarketSelectionSheet(!showMarketSelectionSheet)}
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>
            Crypto Market
          </ActionsheetSectionHeaderText>
          <ActionsheetItem
            onPress={() => handleCloseMarketSelection('robinhood')}
          >
            <ActionsheetItemText>Robinhood</ActionsheetItemText>
          </ActionsheetItem>

          <ActionsheetItem onPress={() => handleCloseMarketSelection('bitso')}>
            <ActionsheetItemText>Bitso</ActionsheetItemText>
          </ActionsheetItem>
        </ActionsheetContent>
      </Actionsheet>

      <Actionsheet
        isOpen={showPlayTypeSelectionSheet}
        onClose={() =>
          setShowPlayTypeSelectionSheet(!showPlayTypeSelectionSheet)
        }
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>Play Type</ActionsheetSectionHeaderText>
          <ActionsheetItem onPress={() => handleClosePlayTypeSelection('BULL')}>
            <ActionsheetItemText>Bull Plays</ActionsheetItemText>
          </ActionsheetItem>

          <ActionsheetItem onPress={() => handleClosePlayTypeSelection('BEAR')}>
            <ActionsheetItemText>Bear Plays</ActionsheetItemText>
          </ActionsheetItem>
        </ActionsheetContent>
      </Actionsheet>
    </>
  );
}
const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    paddingBottom: 10,
    paddingTop: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
});
