import React, { useContext, useEffect } from 'react';
import {
  Center,
  Heading,
  Box,
  Button,
  VStack,
  ButtonText,
  Text,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputField,
  Image,
  useToast,
  Toast,
  ToastTitle,
  Link,
  LinkText,
  ButtonGroup,
  HStack,
} from '@gluestack-ui/themed';
import { dashboardAPI } from '../DashboardAPI';
import { AuthContext } from '../AuthProvider';

import { SafeAreaView, StyleSheet } from 'react-native';
import Loading from './shared/Loading';
import { useNavigation } from '@react-navigation/native';
import Login from './shared/Login';

const RobinhoodIcon = require('../assets/robinhood.svg');

export default function RobinhoodLogin(params) {
  const {
    user,
    refreshMarketApps,
    rhEmail,
    rhCryptoSetUp,
    rhSessionExpirationDate,
    setIgnoreRhSessionExpirationDate,
    authLoading,
    marketAppsLoading,
  } = useContext(AuthContext);

  const navigation = useNavigation();

  const [showTwoFaPrompt, setShowTwoFaPrompt] = React.useState(false);
  const [twoFaOrSms, setTwoFaOrSms] = React.useState('2fa');
  const [twoFaCode, setTwoFaCodee] = React.useState('');
  const [robinhoodPassword, setRobinhoodPassword] = React.useState('');
  const [robinhoodEmail, setRobinhoodEmail] = React.useState(
    rhEmail === false ? '' : rhEmail,
  );
  const [existingUser, setExistingUser] = React.useState(
    rhEmail === false ? false : true,
  );
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (rhEmail !== false && rhEmail !== null) {
      setRobinhoodEmail(rhEmail);
    }
  }, [
    rhEmail,
    rhCryptoSetUp,
    rhSessionExpirationDate,
    authLoading,
    marketAppsLoading,
    user,
  ]);
  const toast = useToast();

  const postpone = () => {
    setIgnoreRhSessionExpirationDate(true);
  };

  const submitTwoFaCode = () => {
    setLoading(true);

    const twoFaCodeCleaned = twoFaCode.replace(/\s+/g, '');

    const value = {
      verification_type: twoFaOrSms,
      two_fa_code: twoFaCodeCleaned,
    };

    dashboardAPI
      .robinhoodTwoFa(user, value)
      .then((x) => {
        if (x.data.yay === 'yay') {
          navigation.navigate('CryptoPilot');
          refreshMarketApps(user);
          toast.show({
            placement: 'top',
            render: ({ id }) => {
              return (
                <Toast nativeId={id} action="success" variant="solid">
                  <VStack space="xs">
                    <ToastTitle>
                      {existingUser
                        ? 'Robinhood Session Refreshed'
                        : 'Robinhood Session Configured'}
                    </ToastTitle>
                  </VStack>
                </Toast>
              );
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setShowTwoFaPrompt(false);
        setLoading(false);
        setTwoFaCodee('');
        setRobinhoodPassword('');
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable to Login into Robinhood</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      });
  };

  const loginInfo = () => {
    //if never signed up to robinhood no banner
    if (rhEmail === null) {
      return <></>;
    }
    if (!rhCryptoSetUp) {
      return (
        <>
          <Heading>Missing Robinhood Crypto Account</Heading>
          <Text>
            Your Robinhood Account does not have a corresponding Crypto Account
          </Text>
          <Text>CryptoPilot.app is not able to automate crypto purchases</Text>
          <Text mt={10}>Steps for Crypto Account Creation:</Text>
          <Link href="https://robinhood.com/crypto/BTC">
            <LinkText>
              1. Go to your Robinhood App and purchase $1 USD of Bitcoin (BTC)
              or any other cryptocurrency.
            </LinkText>
          </Link>
          <Text>
            2. Afterwards go through the re-authentication process below
          </Text>
        </>
      );
    }
    if (rhSessionExpirationDate < new Date()) {
      return (
        <>
          <Heading>Expired Robinhood Session</Heading>
          <Text>
            CryptoPilot.app is not able to continue automating crypto purchases
          </Text>
          <Text mt={7}>
            Go through the re-authentication process below to refresh session
          </Text>
        </>
      );
    }
    return <></>;
  };

  const login = () => {
    setLoading(true);

    const value = {
      robinhood_email: robinhoodEmail,
      robinhood_pw: robinhoodPassword,
    };

    dashboardAPI
      .robinhoodSignIn(user, value)
      .then((x) => {
        setLoading(false);
        if (x.data.two_fa_needed) {
          setTwoFaOrSms(x.data.verification_type);
          setShowTwoFaPrompt(true);
        } else {
          navigation.navigate('CryptoPilot');
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable to Login into Robinhood</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      });
  };

  if (authLoading || marketAppsLoading) {
    return (
      <SafeAreaView>
        <Center>
          <Loading />
        </Center>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView>
      {loading ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <>
          {user !== false ? (
            <Box>
              <Center style={styles.card}>
                {/*<Image*/}
                {/*  alt={'Robinhood Icon'}*/}
                {/*  size={'md'}*/}
                {/*  borderRadius="$none"*/}
                {/*  source={RobinhoodIcon}*/}
                {/*/>*/}
                {showTwoFaPrompt ? (
                  <>
                    <VStack w="$full" p={10}>
                      <FormControl mt={36}>
                        <Heading>Two-Factor Authentication</Heading>
                        {twoFaOrSms !== 'mfa_required' ? (
                          <Text>
                            Enter the code sent to your phone to complete the
                            login process.
                          </Text>
                        ) : (
                          <Text>
                            Open your Authenticator app, and enter the number
                            shown to sign in.
                          </Text>
                        )}
                        <FormControlLabel>
                          <FormControlLabelText>
                            Authentication Code
                          </FormControlLabelText>
                        </FormControlLabel>
                        <Input
                          aria-label={'2 Factor Authentication Token'}
                          size={'lg'}
                          w="$full"
                        >
                          <InputField
                            value={twoFaCode}
                            onChangeText={setTwoFaCodee}
                            type={'text'}
                            variant="rounded"
                          />
                        </Input>

                        <Button
                          size={'lg'}
                          onPress={() => submitTwoFaCode()}
                          mt={10}
                        >
                          <ButtonText>Submit</ButtonText>
                        </Button>
                      </FormControl>
                    </VStack>
                  </>
                ) : (
                  <>
                    <VStack w="$full" p={10}>
                      {loginInfo()}
                      <FormControl mt={15}>
                        <FormControlLabel>
                          <FormControlLabelText>
                            Robinhood Account Email
                          </FormControlLabelText>
                        </FormControlLabel>
                        <Input
                          aria-label={'Robinhood Account Email'}
                          size={'lg'}
                          w="$full"
                        >
                          <InputField
                            value={robinhoodEmail}
                            onChangeText={setRobinhoodEmail}
                            variant="rounded"
                          />
                        </Input>
                        <FormControlLabel>
                          <FormControlLabelText>
                            Robinhood Account Password
                          </FormControlLabelText>
                        </FormControlLabel>
                        <Input
                          aria-label={'Robinhood Account Password'}
                          size={'lg'}
                          w="$full"
                        >
                          <InputField
                            value={robinhoodPassword}
                            onChangeText={setRobinhoodPassword}
                            type={'password'}
                            variant="rounded"
                          />
                        </Input>
                      </FormControl>
                    </VStack>
                    <ButtonGroup>
                      {params.ignorebutton !== undefined &&
                      params.ignorebutton ? (
                        <Button
                          size={'lg'}
                          mt={20}
                          action={'secondary'}
                          variant={'outline'}
                          onPress={() => postpone()}
                        >
                          <ButtonText>Postpone</ButtonText>
                        </Button>
                      ) : (
                        <Button
                          size={'lg'}
                          mt={20}
                          action={'negative'}
                          variant={'outline'}
                          onPress={() => navigation.navigate('Home')}
                        >
                          <ButtonText>Cancel</ButtonText>
                        </Button>
                      )}

                      <Button size={'lg'} onPress={() => login()} mt={20}>
                        <ButtonText>Sign In</ButtonText>
                      </Button>
                    </ButtonGroup>
                  </>
                )}
              </Center>
            </Box>
          ) : (
            <Center style={styles.card}>
              <Heading mb={20}>Robinhood Account Config Page</Heading>
              <Text>
                To continue configuring Robinhood Account details, Login to your
                CryptoPilot.app Account
              </Text>
              <Login redirect={'RobinhoodLogin'} />
            </Center>
          )}
        </>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
});
