import React, { useContext, useEffect } from 'react';
import {
  Alert,
  AlertText,
  Button,
  ButtonText,
  Heading,
  Text,
  View,
  VStack,
  HStack,
  Toast,
  ToastTitle,
  useToast,
  Center,
  Box,
  ButtonGroup,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetSectionHeaderText,
  Actionsheet,
  Avatar,
  AvatarFallbackText,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  ChevronUpIcon,
  ChevronDownIcon,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger,
  AccordionContentText,
  AccordionTitleText,
  AccordionContent,
  Accordion,
  AccordionIcon,
  Divider,
  Pressable,
  Card,
  Tooltip,
  TooltipContent,
  TooltipText,
} from '@gluestack-ui/themed';

import { dashboardAPI } from '../DashboardAPI';
import Play from './shared/Play';
import Loading from './shared/Loading';
import { AuthContext } from '../AuthProvider';
import { useNavigation } from '@react-navigation/native';
import { LineChart } from 'react-native-wagmi-charts';

import {
  Dimensions,
  PixelRatio,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import CryptoAvatar from './shared/CryptoAvatar';
import RoiSpeedometer from './shared/RoiSpeedometer';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { LinearGradient } from 'expo-linear-gradient';
import ConfettiCannon from 'react-native-confetti-cannon';

export default function PlayDetails({ route }) {
  const { user, exchangePrices } = useContext(AuthContext);
  const navigation = useNavigation();

  const [loading, setLoading] = React.useState(true);

  const [play, setPlay] = React.useState(null);
  const [fund, setFund] = React.useState(null);

  const [exchangePair, setExchangePair] = React.useState(null);
  const [currency, setCurrency] = React.useState(null);
  const [amountInFiatCurrency, setAmountInFiatCurrency] = React.useState(0.0);
  const [startingTransactionStatus, setStartingTransactionStatus] =
    React.useState('');
  const [endingTransactionStatus, setEndingTransactionStatus] =
    React.useState('');
  const [startingTransactionVariant, setStartingTransactionVariant] =
    React.useState('accent');
  const [endingTransactionVariant, setEndingTransactionVariant] =
    React.useState('accent');
  const [endingTransactionAmount, setEndingTransactionAmount] =
    React.useState('');
  const [chartData, setChartData] = React.useState(null);
  const [startIndex, setStartIndex] = React.useState(0);
  const [endIndex, setEndIndex] = React.useState(0);
  const [sellIndex, setSellIndex] = React.useState(0);
  const [endIndexColor, setEndIndexColor] = React.useState('red');
  const [startIndexColor, setStartIndexColor] = React.useState('green');
  const [dateRange, setDateRange] = React.useState('3M');
  const [buyDate, setBuyDate] = React.useState(null);
  const [createDate, setCreateDate] = React.useState(null);
  const [buyIndex, setBuyIndex] = React.useState(0);
  const [sellDate, setSellDate] = React.useState(null);
  const [actualSellDate, setActualSellDate] = React.useState(null);

  const [maxChart, setMaxChart] = React.useState(null);
  const [showConfetti, setShowConfetti] = React.useState(false);
  const [minChart, setMinChart] = React.useState(null);
  const [
    startingTransactionHorizontalLine,
    setStartingTransactionHorizontalLine,
  ] = React.useState(null);
  const [endingTransactionHorizontalLine, setEndingTransactionHorizontalLine] =
    React.useState(null);

  const [startingTransactionInfo, setStartingTransactionInfo] =
    React.useState('');
  const [endingTransactionInfo, setEndingTransactionInfo] = React.useState('');
  const [doesPlayStartBeforeChart, setDoesPlayStartBeforeChart] =
    React.useState(false);

  const [showEditActionSheet, setShowEditActionSheet] = React.useState(false);
  const [roiIncrease, setRoiIncrease] = React.useState(0);
  const [originalRoi, setOriginalRoi] = React.useState(4);
  const [roi, setRoi] = React.useState(4);
  const [totalInvestment, setTotalInvestment] = React.useState(100);

  const [newGains, setNewGains] = React.useState(100 * (4 / 100.0));
  const [endPrice, setEndPrice] = React.useState(0.0);
  const [startPrice, setStartPrice] = React.useState(0.0);
  const [gainsToolTipState, setGainsToolTipState] = React.useState(false);
  const [savingsToolTipState, setSavingsToolTipState] = React.useState(false);

  const [savings, setSavings] = React.useState(0);
  const [gains, setGains] = React.useState(0);

  const setRoiIncreaseAndIncreaseRoi = (newRoiIncrease) => {
    setRoiIncrease(newRoiIncrease);
    setRoi(newRoiIncrease + originalRoi);
    setNewGains(
      parseFloat(
        parseFloat(
          totalInvestment * ((originalRoi + newRoiIncrease) / 100.0),
        ).toFixed(2),
      ),
    );
    if (play.starting_transaction_type === 'buy') {
      setEndPrice(
        parseFloat(startPrice * (1.0 + (originalRoi + newRoiIncrease) / 100.0)),
      );
    } else {
      setEndPrice(
        parseFloat(startPrice * (1.0 - (originalRoi + newRoiIncrease) / 100.0)),
      );
    }
  };

  let robinhoodAndBinance = ['robinhood', 'binance'];

  function loadPlay() {
    if (route.params.play !== undefined) {
      dashboardAPI.getPlay(route.params.play).then((x) => {
        let play1 = x.data.play;
        let fund1 = x.data.fund;
        let took = Date.now() - Date.parse(play1.create_ts);
        let took_number = (took / (60 * 60 * 1000)).toFixed(0);
        let date_range = '3M';
        if (took_number < 5) {
          date_range = '6H';
        } else if (took_number < 10) {
          date_range = '12H';
        } else if (took_number < 24) {
          date_range = '1D';
        } else if (took_number < 24 * 7) {
          date_range = '1W';
        } else if (took_number < 24 * 30) {
          date_range = '1M';
        }

        handleTimePeriodSelect(date_range, play1);

        let startingTransactionStatus2 = '';
        let startingTransactionVariant2 = 'accent';
        if (
          (play1.detailed_status !== undefined &&
            play1.detailed_status.starting_transaction_has_been_fulfilled !==
              undefined &&
            new Date(
              play1.detailed_status.starting_transaction_has_been_fulfilled,
            ).getTime() > 0) ||
          play1.status === 'closed'
        ) {
          startingTransactionStatus2 = ' -- Fulfilled';
          startingTransactionVariant2 = 'accent';
        } else if (
          play1.detailed_status !== undefined &&
          play1.detailed_status.starting_transaction_submitted !== undefined &&
          new Date(
            play1.detailed_status.starting_transaction_submitted,
          ).getTime() > 0
        ) {
          startingTransactionStatus2 = ' -- Submitted';
          startingTransactionVariant2 = 'solid';
        } else {
          startingTransactionStatus2 = '';
          startingTransactionVariant2 = 'outline';
        }
        setStartingTransactionStatus(startingTransactionStatus2);
        setStartingTransactionVariant(startingTransactionVariant2);

        let endingTransactionStatus2 = '';
        let endingTransactionVariant2 = 'accent';

        if (
          (play1.detailed_status !== undefined &&
            play1.detailed_status.ending_transaction_has_been_fulfilled !==
              undefined &&
            new Date(
              play1.detailed_status.ending_transaction_has_been_fulfilled,
            ).getTime() > 0) ||
          play1.status === 'closed'
        ) {
          endingTransactionStatus2 = ' -- Fulfilled';
          endingTransactionVariant2 = 'accent';
        } else if (
          play1.detailed_status !== undefined &&
          play1.detailed_status.ending_transaction_submitted !== undefined &&
          new Date(
            play1.detailed_status.ending_transaction_submitted,
          ).getTime() > 0
        ) {
          endingTransactionStatus2 = ' -- Submitted';
          endingTransactionVariant2 = 'solid';
        } else {
          endingTransactionStatus2 = '';
          endingTransactionVariant2 = 'outline';
        }

        let endingTransactionAmount2 = '';
        if (play1.starting_transaction_type === 'buy') {
          if (robinhoodAndBinance.includes(play1.market_app)) {
            endingTransactionAmount2 =
              (
                play1.amount_in_usd *
                (play1.ending_price / parseFloat(play1.price))
              ).toFixed(2) + ' USD';
          }
          if (play1.market_app === 'bitso' && play1.currency === 'mxn') {
            endingTransactionAmount2 =
              (
                parseFloat(play1.amount_in_mxn) *
                (play1.ending_price / parseFloat(play1.price))
              ).toFixed(2) + ' MXN';
          }
          if (play1.market_app === 'bitso' && play1.currency === 'usd') {
            endingTransactionAmount2 =
              (
                parseFloat(play1.amount_in_usd) *
                (play1.ending_price / parseFloat(play1.price))
              ).toFixed(2) + ' USD';
          }
          if (play1.market_app === 'bitso' && play1.currency === 'cop') {
            endingTransactionAmount2 =
              (
                parseFloat(play1.amount_in_cop) *
                (play1.ending_price / parseFloat(play1.price))
              ).toFixed(2) + ' COP';
          }
        }
        if (play1.starting_transaction_type === 'sell') {
          if (robinhoodAndBinance.includes(play1.market_app)) {
            endingTransactionAmount2 =
              (
                play1.amount_in_usd *
                (parseFloat(play1.price) / play1.ending_price)
              ).toFixed(2) + ' USD';
          }
          if (play1.market_app === 'bitso' && play1.currency === 'mxn') {
            endingTransactionAmount2 =
              (
                parseFloat(play1.amount_in_mxn) *
                (parseFloat(play1.price) / play1.ending_price)
              ).toFixed(2) + ' MXN';
          }
          if (play1.market_app === 'bitso' && play1.currency === 'usd') {
            endingTransactionAmount2 =
              (
                parseFloat(play1.amount_in_usd) *
                (parseFloat(play1.price) / play1.ending_price)
              ).toFixed(2) + ' USD';
          }
          if (play1.market_app === 'bitso' && play1.currency === 'cop') {
            endingTransactionAmount2 =
              (
                parseFloat(play1.amount_in_cop) *
                (parseFloat(play1.price) / play1.ending_price)
              ).toFixed(2) + ' COP';
          }
        }

        setEndingTransactionAmount(endingTransactionAmount2);
        setEndingTransactionStatus(endingTransactionStatus2);
        setEndingTransactionVariant(endingTransactionVariant2);

        if (play1.starting_transaction_type === 'sell') {
          setStartIndexColor('red');
          setEndIndexColor('green');
        } else {
          setStartIndexColor('green');
          setEndIndexColor('red');
        }

        let playGains = 0.0;
        if (play1.starting_transaction_type === 'buy') {
          playGains = play1.ending_price / parseFloat(play1.price) - 1;
        }

        if (play1.starting_transaction_type === 'sell') {
          playGains = parseFloat(play1.price) / play1.ending_price - 1;
        }
        let startingTransactionInfo2 = '';
        let endingTransactionInfo2 = '';
        if (
          robinhoodAndBinance.includes(play1.market_app) &&
          play1.starting_transaction_type === 'buy'
        ) {
          startingTransactionInfo2 =
            'BUY ' + play1.amount_in_usd.toFixed(2) + ' USD';
          endingTransactionInfo2 =
            'SELL ' +
            (play1.amount_in_usd * (1 + playGains)).toFixed(2) +
            ' USD';
        }
        if (
          robinhoodAndBinance.includes(play1.market_app) &&
          play1.starting_transaction_type === 'sell'
        ) {
          startingTransactionInfo2 =
            'SELL ' + play1.amount_in_usd.toFixed(2) + ' USD';
          endingTransactionInfo2 =
            'BUY ' +
            (play1.amount_in_usd * (1 + playGains)).toFixed(2) +
            ' USD';
        }
        if (
          play1.market_app === 'bitso' &&
          play1.starting_transaction_type === 'buy'
        ) {
          if (play1.amount_in_mxn) {
            startingTransactionInfo2 =
              'BUY ' + parseFloat(play1.amount_in_mxn).toFixed(0) + ' MXN';
            endingTransactionInfo2 =
              'SELL ' +
              (parseFloat(play1.amount_in_mxn) * (1 + playGains)).toFixed(0) +
              ' MXN';
          }
          if (play1.amount_in_usd) {
            startingTransactionInfo2 =
              'BUY ' + parseFloat(play1.amount_in_usd).toFixed(2) + ' USD';
            endingTransactionInfo2 =
              'SELL ' +
              (parseFloat(play1.amount_in_usd) * (1 + playGains)).toFixed(2) +
              ' USD';
          }
          if (play1.amount_in_cop) {
            startingTransactionInfo2 =
              'BUY ' + parseFloat(play1.amount_in_cop).toFixed(0) + ' COP';
            endingTransactionInfo2 =
              'SELL ' +
              (parseFloat(play1.amount_in_cop) * (1 + playGains)).toFixed(0) +
              ' COP';
          }
        }
        if (
          play1.market_app === 'bitso' &&
          play1.starting_transaction_type === 'sell'
        ) {
          if (play1.amount_in_mxn) {
            startingTransactionInfo2 =
              'SELL ' + parseFloat(play1.amount_in_mxn).toFixed(0) + ' MXN';
            endingTransactionInfo2 =
              'BUY ' +
              (parseFloat(play1.amount_in_mxn) * (1 + playGains)).toFixed(0) +
              ' MXN';
          }
          if (play1.amount_in_usd) {
            startingTransactionInfo2 =
              'SELL ' + parseFloat(play1.amount_in_usd).toFixed(2) + ' USD';
            endingTransactionInfo2 =
              'BUY ' +
              (parseFloat(play1.amount_in_usd) * (1 + playGains)).toFixed(2) +
              ' USD';
          }
          if (play1.amount_in_cop) {
            startingTransactionInfo2 =
              'SELL ' + parseFloat(play1.amount_in_cop).toFixed(0) + ' COP';
            endingTransactionInfo2 =
              'BUY ' +
              (parseFloat(play1.amount_in_cop) * (1 + playGains)).toFixed(0) +
              ' COP';
          }
        }
        setStartingTransactionInfo(startingTransactionInfo2);
        setEndingTransactionInfo(endingTransactionInfo2);

        //console.log(play1)

        const totalInvestment2 =
          parseFloat(play1.price) * parseFloat(play1.quantity);
        setTotalInvestment(totalInvestment2);
        if (play1.starting_transaction_type === 'sell') {
          const roi = parseFloat(
            (
              (parseFloat(play1.price) / play1.ending_price - 1.0) *
              100
            ).toFixed(1),
          );
          setOriginalRoi(roi);
          setRoi(roi);
          setNewGains(
            parseFloat(parseFloat(totalInvestment2 * (roi / 100.0)).toFixed(2)),
          );
        } else {
          const roi = parseFloat(
            (
              (play1.ending_price / parseFloat(play1.price) - 1.0) *
              100
            ).toFixed(1),
          );
          setOriginalRoi(roi);
          setRoi(roi);
          setNewGains(
            parseFloat(parseFloat(totalInvestment2 * (roi / 100.0)).toFixed(2)),
          );
        }

        if (
          play1.starting_transaction_type === 'buy' &&
          play1.status === 'closed' &&
          play1.purpose === 'crypto'
        ) {
          let gains_and_savings =
            parseFloat(play1.quantity) - parseFloat(play1.ending_quantity);
          let theorical_gains = parseFloat(play1.quantity) * playGains;
          let savings = gains_and_savings - theorical_gains;
          if (savings > 0.0) {
            setSavings(savings);
            setGains(theorical_gains);
          } else {
            setSavings(0);
            setGains(
              parseFloat(play1.quantity) - parseFloat(play1.ending_quantity),
            );
          }
        } else if (
          play1.starting_transaction_type === 'buy' &&
          play1.status === 'closed' &&
          play1.purpose === 'usd'
        ) {
          if (robinhoodAndBinance.includes(play1.market_app)) {
            setGains(playGains * play1.amount_in_usd);
          }
          if (play1.market_app === 'bitso' && play1.currency === 'mxn') {
            setGains(playGains * play1.amount_in_mxn);
          }
          if (play1.market_app === 'bitso' && play1.currency === 'cop') {
            setGains(playGains * play1.amount_in_cop);
          }
          if (play1.market_app === 'bitso' && play1.currency === 'usd') {
            setGains(playGains * play1.amount_in_usd);
          }
          setSavings(0);
          setGains(playGains);
        } else if (
          play1.starting_transaction_type === 'sell' &&
          play1.status === 'closed' &&
          play1.purpose === 'crypto'
        ) {
          let gains_and_savings =
            parseFloat(play1.ending_quantity) - parseFloat(play1.quantity);
          let theorical_gains = parseFloat(play1.quantity) * playGains;
          let savings = gains_and_savings - theorical_gains;
          if (savings > 0.0) {
            setSavings(savings);
            setGains(theorical_gains);
          } else {
            setSavings(0);
            setGains(
              parseFloat(play1.ending_quantity) - parseFloat(play1.quantity),
            );
          }
        } else if (
          play1.starting_transaction_type === 'sell' &&
          play1.status === 'closed' &&
          play1.purpose === 'usd'
        ) {
          if (robinhoodAndBinance.includes(play1.market_app)) {
            setGains(playGains * play1.amount_in_usd);
          }
          if (play1.market_app === 'bitso' && play1.currency === 'mxn') {
            setGains(playGains * play1.amount_in_mxn);
          }
          if (play1.market_app === 'bitso' && play1.currency === 'cop') {
            setGains(playGains * play1.amount_in_cop);
          }
          if (play1.market_app === 'bitso' && play1.currency === 'usd') {
            setGains(playGains * play1.amount_in_usd);
          }
          setSavings(0);
          setGains(playGains);
        }

        setRoiIncrease(0);
        setStartPrice(parseFloat(play1.price).toFixed(4));
        setEndPrice(play1.ending_price);
        setPlay(play1);
        if (robinhoodAndBinance.includes(play1.market_app)) {
          setExchangePair(play1.crypto_symbol);
          setCurrency('USD');
          setAmountInFiatCurrency(play1.amount_in_usd);
        }
        if (play1.market_app === 'bitso' && play1.currency === 'mxn') {
          setExchangePair(play1.crypto_symbol + '_MXN');
          setCurrency('MXN');
          setAmountInFiatCurrency(play1.amount_in_mxn);
        }
        if (play1.market_app === 'bitso' && play1.currency === 'usd') {
          setExchangePair(play1.crypto_symbol);
          setCurrency('USD');
          setAmountInFiatCurrency(play1.amount_in_usd);
        }
        if (play1.market_app === 'bitso' && play1.currency === 'cop') {
          setExchangePair(play1.crypto_symbol + '_COP');
          setCurrency('COP');
          setAmountInFiatCurrency(play1.amount_in_cop);
        }
        console.log(fund1);
        setFund(fund1);
      });
    }
  }
  useEffect(() => {
    (async () => {
      setPlay(null);
      if (showConfetti) {
        setShowConfetti(false);
      }
      loadPlay();
    })();
  }, [route.params.play]);

  useEffect(() => {
    (async () => {
      if (play !== null && play.status === 'closed' && chartData !== null) {
        setShowConfetti(true);
      }
    })();
  }, [play, chartData]);
  useEffect(() => {
    (async () => {})();
  }, [user, fund]);

  function resuseFund(fund_id) {
    dashboardAPI.reuseFund(user, fund_id).then(loadPlay());
  }

  function handleTimePeriodSelect(dateRangee, play2) {
    setLoading(true);
    setBuyDate(null);
    setSellDate(null);
    setCreateDate(null);
    setBuyIndex(0);
    setStartIndex(0);
    setEndIndex(0);
    setDoesPlayStartBeforeChart(false);

    setActualSellDate(null);
    setSellIndex(0);
    setStartingTransactionHorizontalLine(null);
    setEndingTransactionHorizontalLine(null);

    dashboardAPI
      .getCharts(dateRangee, play2.crypto_symbol, play2.currency)
      .then((x2) => {
        let newData = [];
        let startIndexNew = null;
        let endIndexNew = null;

        let sellIndex2 = null;

        let buyIndex2 = null;

        let minNew = null;
        let maxNew = null;

        let createdate2 = null;
        let buydate2 = null;
        let selldate2 = null;
        let actualSellDate2 = null;
        let doesPlaystartbeforechart = false;
        for (let i = 0; i < x2.data.candles.length; i++) {
          if (i == 0) {
            doesPlaystartbeforechart =
              new Date(x2.data.candles[i].begins_at) >
              new Date(play2.create_ts);
          }

          if (minNew === null || x2.data.candles[i].close_price < minNew) {
            minNew = x2.data.candles[i].close_price;
          }
          if (maxNew === null || x2.data.candles[i].close_price > maxNew) {
            maxNew = x2.data.candles[i].close_price;
          }
          const obj = {
            timestamp: new Date(x2.data.candles[i].begins_at),
            value: x2.data.candles[i].close_price,
            price: x2.data.candles[i].close_price,
          };
          if (
            startIndexNew === null &&
            new Date(x2.data.candles[i].begins_at) > new Date(play2.create_ts)
          ) {
            createdate2 = new Date(x2.data.candles[i].begins_at);

            startIndexNew = i;
          }

          if (
            buyIndex2 === null &&
            play2.detailed_status !== undefined &&
            play2.detailed_status.starting_transaction_has_been_fulfilled !==
              undefined &&
            new Date(
              play2.detailed_status.starting_transaction_has_been_fulfilled,
            ).getTime() >
              0 !==
              undefined &&
            new Date(x2.data.candles[i].begins_at) >
              new Date(
                play2.detailed_status.starting_transaction_has_been_fulfilled,
              )
          ) {
            buydate2 = new Date(x2.data.candles[i].begins_at);
            //console.log('play2.detailed_status.starting_transaction_has_been_fulfilled', play2.detailed_status.starting_transaction_has_been_fulfilled)
            buyIndex2 = i;
          }

          if (
            play2.status === 'closed' &&
            endIndexNew === null &&
            new Date(x2.data.candles[i].begins_at) > new Date(play2.last_upd_ts)
          ) {
            selldate2 = new Date(x2.data.candles[i].begins_at);

            endIndexNew = i;
          }

          if (
            sellIndex2 === null &&
            play2.detailed_status !== undefined &&
            play2.detailed_status.ending_transaction_has_been_fulfilled !==
              undefined &&
            new Date(
              play2.detailed_status.ending_transaction_has_been_fulfilled,
            ).getTime() > 0 &&
            new Date(x2.data.candles[i].begins_at) >
              new Date(
                play2.detailed_status.ending_transaction_has_been_fulfilled,
              )
          ) {
            actualSellDate2 = new Date(x2.data.candles[i].begins_at);
            sellIndex2 = i;
          }

          newData.push(obj);
        }
        if (endIndexNew === null) {
          if (x2.data.candles.length === 0) {
            endIndexNew = 0;
          } else {
            selldate2 = new Date(
              x2.data.candles[x2.data.candles.length - 1].begins_at,
            );
            endIndexNew = x2.data.candles.length - 1;
          }
        }
        if (startIndexNew === null) {
          if (
            x2.data.candles.length > 1 &&
            new Date(play2.create_ts) >
              new Date(x2.data.candles[x2.data.candles.length - 1].begins_at)
          ) {
            startIndexNew = x2.data.candles.length - 1;
          } else {
            startIndexNew = 0;
          }
        }

        if (createdate2) {
          setCreateDate(createdate2);
        }
        if (buydate2) {
          setBuyDate(buydate2);
        }
        if (selldate2) {
          setSellDate(selldate2);
        }

        if (actualSellDate2) {
          setActualSellDate(actualSellDate2);
          setSellIndex(sellIndex2);
        }

        setChartData(newData);
        setStartIndex(startIndexNew);
        setEndIndex(endIndexNew);
        setDateRange(dateRangee);
        if (buyIndex2) {
          setBuyIndex(buyIndex2);
        }

        let price = parseFloat(play2.price);
        if (price > minNew && price < maxNew) {
          setStartingTransactionHorizontalLine(price);
        } else if (dateRangee === '1M' || dateRangee === '3M') {
          if (price > maxNew) {
            maxNew = price;
          }
          if (price < minNew) {
            minNew = price;
          }
          setStartingTransactionHorizontalLine(price);
        }

        price = play2.ending_price;
        if (price > minNew && price < maxNew) {
          setEndingTransactionHorizontalLine(price);
        } else if (dateRangee === '1M' || dateRangee === '3M') {
          if (price > maxNew) {
            maxNew = price;
          }
          if (price < minNew) {
            minNew = price;
          }
          setEndingTransactionHorizontalLine(price);
        }

        setDoesPlayStartBeforeChart(doesPlaystartbeforechart);

        setMaxChart(maxNew);
        setMinChart(minNew);
        setLoading(false);
      });
  }

  let width = Dimensions.get('window').width;
  let height = Dimensions.get('window').height;
  let height2 = Dimensions.get('screen').height;

  const toast = useToast();

  function copyToClipboard(text) {
    (async () => {
      try {
        await navigator.clipboard.writeText(text);
        /* ✅ Copied successfully */

        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="info" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Link Copied To Clipboard</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      } catch (e) {
        /* ❌ Failed to copy (insufficient permissions) */
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable To Copy Link</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      }
    })();
  }

  return (
    // <SafeAreaView style={{ flex: 8 }}>
    <>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <LinearGradient
          colors={[
            '#ffffff',
            '#ffffff',
            '#ffffff',
            startIndexColor === 'green' ? '#E4FFF4' : '#FEE9E9',
            startIndexColor === 'green' ? '#E4FFF4' : '#FEE9E9',
            startIndexColor === 'green' ? '#E4FFF4' : '#FEE9E9',
            startIndexColor === 'green' ? '#E4FFF4' : '#FEE9E9',
            startIndexColor === 'green' ? '#E4FFF4' : '#FEE9E9',
          ]}
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
          }}
        />
        <View style={{ height: '100%', width: '100%' }}>
          <ScrollView>
            {/*{Platform.OS === 'ios' && (*/}
            {/*  <>*/}
            {/*    <Text> </Text>*/}
            {/*  </>*/}
            {/*)}*/}
            {/*{user ? (*/}
            {/*  <Pressable onPress={() => navigation.navigate('Home')}>*/}
            {/*    <Center>*/}
            {/*      <Heading*/}
            {/*        color={*/}
            {/*          startIndexColor === 'green' ? '$success700' : '$error700'*/}
            {/*        }*/}
            {/*        size={'lg'}*/}
            {/*      >*/}
            {/*        CryptoPilot*/}
            {/*      </Heading>*/}
            {/*    </Center>*/}
            {/*  </Pressable>*/}
            {/*) : (*/}
            {/*  <Pressable onPress={() => navigation.navigate('Home')}>*/}
            {/*    <Center>*/}
            {/*      <Heading*/}
            {/*        color={*/}
            {/*          startIndexColor === 'green' ? '$success700' : '$error700'*/}
            {/*        }*/}
            {/*        size={'lg'}*/}
            {/*      >*/}
            {/*        CryptoPilot - Sign Up Today🤑*/}
            {/*      </Heading>*/}
            {/*    </Center>*/}
            {/*  </Pressable>*/}
            {/*)}*/}
            {/*<Divider />*/}

            {!loading && chartData !== null && play !== null ? (
              <>
                <Heading ml={10} size={'sm'}>
                  Play Details
                </Heading>

                <LineChart.Provider
                  yRange={{ min: minChart, max: maxChart }}
                  data={chartData}
                >
                  <View
                    styles={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ScrollView
                      horizontal={true}
                      contentContainerStyle={{
                        paddingHorizontal: 20,
                        paddingBottom: 12,
                        paddingTop: 10,
                      }}
                    >
                      <CryptoAvatar size={'sm'} crypto={play.crypto_symbol} />
                      <VStack>
                        <Text>
                          {play.crypto_symbol} - {dateRange}
                        </Text>
                        {exchangePrices !== null && play.currency === 'usd' && (
                          <Heading size={'sm'}>
                            $
                            {exchangePrices[play.crypto_symbol].toFixed(
                              dashboardAPI
                                .CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION[
                                play.crypto_symbol
                              ],
                            )}{' '}
                            USD
                          </Heading>
                        )}
                        {exchangePrices !== null && play.currency === 'mxn' && (
                          <Heading size={'sm'}>
                            $
                            {exchangePrices[
                              play.crypto_symbol + '_MXN'
                            ].toFixed(
                              dashboardAPI
                                .CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION[
                                play.crypto_symbol
                              ],
                            )}{' '}
                            MXN
                          </Heading>
                        )}
                        {exchangePrices !== null && play.currency === 'cop' && (
                          <Heading size={'sm'}>
                            $
                            {exchangePrices[
                              play.crypto_symbol + '_COP'
                            ].toFixed(
                              dashboardAPI
                                .CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION[
                                play.crypto_symbol
                              ],
                            )}{' '}
                            COP
                          </Heading>
                        )}
                      </VStack>

                      {play !== null &&
                        play.status === 'closed' &&
                        chartData !== null && (
                          <>
                            {user !== false && user.email === play.user ? (
                              <></>
                            ) : (
                              <Box style={styles.card_metric}>
                                <Heading size={'sm'} ml={7} mt={1}>
                                  User
                                </Heading>
                                <HStack>
                                  <Avatar
                                    bgColor="$amber600"
                                    size="md"
                                    borderRadius="$full"
                                    mr={2}
                                  ></Avatar>
                                  <Text m={5}>
                                    {dashboardAPI.hideEmail(play.user)}
                                  </Text>
                                </HStack>
                              </Box>
                            )}

                            <Tooltip
                              isOpen={gainsToolTipState}
                              onClose={() => setGainsToolTipState(false)}
                              onOpen={() => setGainsToolTipState(true)}
                              placement="bottom"
                              trigger={(triggerProps) => {
                                return (
                                  <TouchableOpacity
                                    style={styles.card_metric}
                                    {...triggerProps}
                                    onPressIn={() => setGainsToolTipState(true)}
                                    onPressOut={() =>
                                      setGainsToolTipState(false)
                                    }
                                  >
                                    <Heading size={'sm'} mt={5} ml={5} mr={5}>
                                      Gained 🤑
                                    </Heading>

                                    <Text m={5}>
                                      {gains.toFixed(
                                        dashboardAPI
                                          .CRYPTO_MAPPED_TO_AMOUNT_DECIMAL_PRECISION[
                                          play.crypto_symbol
                                        ],
                                      )}{' '}
                                      {play.purpose === 'crypto'
                                        ? play.crypto_symbol
                                        : currency}
                                      {play.purpose === 'crypto' &&
                                        exchangePrices !== false &&
                                        ' ~ $' +
                                          (
                                            gains * exchangePrices[exchangePair]
                                          ).toFixed(2) +
                                          ' ' +
                                          currency}
                                    </Text>
                                  </TouchableOpacity>
                                );
                              }}
                            >
                              <TooltipContent>
                                <TooltipText>Amount won from Play</TooltipText>
                              </TooltipContent>
                            </Tooltip>

                            {play.purpose === 'crypto' &&
                            exchangePrices !== false &&
                            savings * exchangePrices[play.crypto_symbol] >
                              0.01 ? (
                              <Tooltip
                                isOpen={savingsToolTipState}
                                onClose={() => setSavingsToolTipState(false)}
                                onOpen={() => setSavingsToolTipState(true)}
                                placement="bottom"
                                trigger={(triggerProps) => {
                                  return (
                                    <TouchableOpacity
                                      style={styles.card_metric_savings}
                                      {...triggerProps}
                                      onPressIn={() =>
                                        setSavingsToolTipState(true)
                                      }
                                      onPressOut={() =>
                                        setSavingsToolTipState(false)
                                      }
                                    >
                                      <Heading size={'sm'} mt={5} ml={5} mr={5}>
                                        Saved 🏦
                                      </Heading>

                                      <Text m={5}>
                                        {savings.toFixed(
                                          dashboardAPI
                                            .CRYPTO_MAPPED_TO_AMOUNT_DECIMAL_PRECISION[
                                            play.crypto_symbol
                                          ],
                                        )}{' '}
                                        {play.crypto_symbol}
                                        {play.purpose === 'crypto' &&
                                          exchangePrices !== false &&
                                          ' ~ $' +
                                            (
                                              savings *
                                              exchangePrices[exchangePair]
                                            ).toFixed(2) +
                                            ' ' +
                                            currency}
                                      </Text>
                                    </TouchableOpacity>
                                  );
                                }}
                              >
                                <TooltipContent>
                                  <TooltipText>
                                    Long term crypto savings
                                  </TooltipText>
                                </TooltipContent>
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                            {fund !== null &&
                              fund.status === 'done_with_one_time_use' &&
                              user !== false &&
                              fund.user === user.email && (
                                <Box>
                                  <Heading p={5}>Fund Released</Heading>
                                  <Text p={5}>
                                    Re-Use Fund For Another Play?
                                  </Text>

                                  <ButtonGroup p={5}>
                                    <Button onPress={() => resuseFund(fund.id)}>
                                      <ButtonText>
                                        Re-Use{' $'}
                                        {(
                                          play.ending_price *
                                          parseFloat(play.ending_quantity)
                                        ).toFixed(2)}{' '}
                                        {currency} FUND
                                      </ButtonText>
                                    </Button>
                                  </ButtonGroup>
                                </Box>
                              )}
                          </>
                        )}
                    </ScrollView>
                  </View>

                  <Center>
                    <LineChart width={width * 0.97} height={height / 3}>
                      <LineChart.Path color={'black'}>
                        <LineChart.Gradient color={startIndexColor} />
                        {startingTransactionHorizontalLine !== null && (
                          <LineChart.HorizontalLine
                            color={startIndexColor}
                            at={{ value: startingTransactionHorizontalLine }}
                          />
                        )}
                        \
                        {endingTransactionHorizontalLine !== null && (
                          <LineChart.HorizontalLine
                            color={endIndexColor}
                            at={{ value: endingTransactionHorizontalLine }}
                          />
                        )}
                        <LineChart.Highlight
                          color="rgba(12, 95, 223, 1)"
                          from={startIndex}
                          to={endIndex}
                        />
                        {buyIndex !== startIndex && (
                          <LineChart.Dot
                            color="rgba(12, 95, 223, 1)"
                            at={startIndex}
                          />
                        )}
                        {buyIndex !== 0 && (
                          <LineChart.Dot
                            color={startIndexColor}
                            at={buyIndex}
                            hasPulse
                          />
                        )}
                        {play.status === 'closed' && (
                          <>
                            {sellIndex === 0 || sellIndex === endIndex ? (
                              <LineChart.Dot
                                color={endIndexColor}
                                at={endIndex}
                                hasPulse
                              />
                            ) : (
                              <>
                                <LineChart.Dot
                                  color={endIndexColor}
                                  at={sellIndex}
                                  hasPulse
                                />
                                <LineChart.Dot color={'blue'} at={endIndex} />
                              </>
                            )}
                          </>
                        )}
                      </LineChart.Path>

                      {/*<LineChart.CursorLine />*/}

                      {play.status === 'closed' && (
                        <>
                          {sellIndex === 0 || sellIndex === endIndex ? (
                            <LineChart.Tooltip
                              at={endIndex}
                              position={
                                endIndexColor === 'green' ? 'bottom' : 'top'
                              }
                            >
                              <LineChart.DatetimeText
                                style={{
                                  backgroundColor: 'rgba(12, 95, 223, 1)',
                                  borderRadius: 4,
                                  color: 'white',
                                  fontSize: 16,
                                  padding: 2,
                                  width: 60,
                                }}
                                format={({ value }) => {
                                  'worklet';
                                  //const formattedDate = yourOwnFormatValueFn(value);
                                  if (endIndexColor === 'red') {
                                    return 'SELL';
                                  }
                                  return 'BUY';
                                  //return endingTransactionInfo
                                }}
                              />
                            </LineChart.Tooltip>
                          ) : (
                            <LineChart.Tooltip
                              at={sellIndex}
                              position={
                                endIndexColor === 'green' ? 'bottom' : 'top'
                              }
                            >
                              <LineChart.DatetimeText
                                style={{
                                  backgroundColor: 'rgba(12, 95, 223, 1)',
                                  borderRadius: 4,
                                  color: 'white',
                                  fontSize: 16,
                                  padding: 2,
                                  width: 60,
                                }}
                                format={({ value }) => {
                                  'worklet';
                                  //const formattedDate = yourOwnFormatValueFn(value);
                                  if (endIndexColor === 'red') {
                                    return 'SELL';
                                  }
                                  return 'BUY';
                                  //return endingTransactionInfo
                                }}
                              />
                            </LineChart.Tooltip>
                          )}
                        </>
                      )}

                      {!doesPlayStartBeforeChart && (
                        <LineChart.Tooltip
                          at={buyIndex !== 0 ? buyIndex : startIndex}
                          position={
                            startIndexColor === 'green' ? 'bottom' : 'top'
                          }
                        >
                          <LineChart.DatetimeText
                            style={{
                              backgroundColor: 'rgba(12, 95, 223, 1)',
                              borderRadius: 4,
                              color: 'white',
                              fontSize: 16,
                              padding: 2,
                              width: buyIndex === 0 ? 200 : 60,
                            }}
                            format={({ value }) => {
                              'worklet';
                              //const formattedDate = yourOwnFormatValueFn(value);
                              if (buyIndex === 0) {
                                if (startIndexColor === 'green') {
                                  return 'PLAY CREATED';
                                }
                                return 'PLAY CREATED';
                              }
                              if (startIndexColor === 'red') {
                                return 'SELL';
                              }
                              return 'BUY';
                              //return startingTransactionInfo
                            }}
                          />
                        </LineChart.Tooltip>
                      )}
                      {/*<LineChart.CursorCrosshair>*/}
                      {/*  <LineChart.HoverTrap />*/}
                      {/*</LineChart.CursorCrosshair>*/}
                    </LineChart>
                  </Center>

                  {play !== null && (
                    <HStack
                      mt={10}
                      space={'4xl'}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button
                        action={'secondary'}
                        onPress={() => handleTimePeriodSelect('6H', play)}
                        variant={dateRange === '6H' ? 'solid' : 'link'}
                        size={'xs'}
                      >
                        <ButtonText> 6H </ButtonText>
                      </Button>
                      <Button
                        action={'secondary'}
                        onPress={() => handleTimePeriodSelect('12H', play)}
                        variant={dateRange === '12H' ? 'solid' : 'link'}
                        size={'xs'}
                      >
                        <ButtonText> 12H </ButtonText>
                      </Button>
                      <Button
                        action={'secondary'}
                        onPress={() => handleTimePeriodSelect('1D', play)}
                        variant={dateRange === '1D' ? 'solid' : 'link'}
                        size={'xs'}
                      >
                        <ButtonText> 1D </ButtonText>
                      </Button>
                      <Button
                        action={'secondary'}
                        onPress={() => handleTimePeriodSelect('1W', play)}
                        variant={dateRange === '1W' ? 'solid' : 'link'}
                        size={'xs'}
                      >
                        <ButtonText> 1W </ButtonText>
                      </Button>
                      <Button
                        action={'secondary'}
                        onPress={() => handleTimePeriodSelect('1M', play)}
                        variant={dateRange === '1M' ? 'solid' : 'link'}
                        size={'xs'}
                      >
                        <ButtonText> 1M </ButtonText>
                      </Button>
                      <Button
                        action={'secondary'}
                        onPress={() => handleTimePeriodSelect('3M', play)}
                        variant={dateRange === '3M' ? 'solid' : 'link'}
                        size={'xs'}
                      >
                        <ButtonText> 3M </ButtonText>
                      </Button>
                    </HStack>
                  )}
                </LineChart.Provider>
              </>
            ) : (
              <Center width={width * 0.97} height={height / 3}>
                <Loading />
              </Center>
            )}

            {play !== null && chartData !== null ? (
              <Play play={play} viewtoggle={false} />
            ) : (
              <Center>
                <Loading />
              </Center>
            )}

            <Box style={styles.card}>
              {user !== false &&
                play !== null &&
                play.status !== 'closed' &&
                user.email === play.user && (
                  <ButtonGroup>
                    <Button
                      size={'lg'}
                      action={'negative'}
                      onPress={() => {
                        dashboardAPI
                          .deleteFund(user, play.funding_id)
                          .then((x) => {
                            navigation.navigate('Home');
                          });
                      }}
                    >
                      <ButtonText>Cancel</ButtonText>
                    </Button>
                    <Button
                      size={'lg'}
                      variant={'outline'}
                      action={'secondary'}
                      onPress={() => {
                        setShowEditActionSheet(true);
                      }}
                    >
                      <ButtonText>Edit</ButtonText>
                    </Button>
                  </ButtonGroup>
                )}
              <Accordion
                m="$5"
                width="90%"
                size="md"
                variant="filled"
                type="single"
                isCollapsible={true}
                isDisabled={false}
              >
                <AccordionItem value="a">
                  <AccordionHeader>
                    <AccordionTrigger>
                      {({ isExpanded }) => {
                        return (
                          <>
                            <AccordionTitleText>
                              What is CryptoPilot.app?
                            </AccordionTitleText>
                            {isExpanded ? (
                              <AccordionIcon as={ChevronUpIcon} ml="$3" />
                            ) : (
                              <AccordionIcon as={ChevronDownIcon} ml="$3" />
                            )}
                          </>
                        );
                      }}
                    </AccordionTrigger>
                  </AccordionHeader>
                  <AccordionContent>
                    <AccordionContentText>
                      Automated trading bot for cryptocurrencies. Connects with
                      your crypto broker (Robinhood, Binance, or Bitso) to place
                      automated buy and sell orders.
                    </AccordionContentText>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
              <ButtonGroup>
                <Button
                  size={'lg'}
                  action={'secondary'}
                  onPress={() =>
                    copyToClipboard(
                      'https://cryptopilot.app/play/' + route.params.play,
                    )
                  }
                >
                  <ButtonText>Share</ButtonText>
                </Button>
                <Button
                  onPress={() => navigation.navigate('About')}
                  action={'secondary'}
                  size={'lg'}
                >
                  <ButtonText>About</ButtonText>
                </Button>
              </ButtonGroup>

              {user === false && (
                <>
                  <Text mt={5} mb={2}>
                    Interested?
                  </Text>
                  <ButtonGroup>
                    <Button
                      variant={'link'}
                      onPress={() => navigation.navigate('Home')}
                      action={'primary'}
                      size={'lg'}
                    >
                      <ButtonText>Create Account</ButtonText>
                    </Button>
                  </ButtonGroup>
                </>
              )}
            </Box>

            {play !== null ? (
              <VStack style={styles.card} mb={40}>
                <Heading size={'sm'}>
                  Starting Transaction{startingTransactionStatus}
                </Heading>

                <Alert
                  mx="$2.5"
                  action={
                    play.starting_transaction_type === 'buy'
                      ? 'success'
                      : 'error'
                  }
                  variant={startingTransactionVariant}
                >
                  <AlertText>
                    {startingTransactionStatus
                      .toLowerCase()
                      .includes('fulfilled') ? (
                      <>
                        {play.starting_transaction_type === 'buy'
                          ? 'BOUGHT'
                          : 'SOLD'}
                      </>
                    ) : (
                      <>
                        {play.starting_transaction_type === 'buy'
                          ? 'BUY'
                          : 'SELL'}
                      </>
                    )}{' '}
                    {(
                      parseFloat(play.price) * parseFloat(play.quantity)
                    ).toFixed(2)}{' '}
                    {currency}
                    {' ~ '}
                    {parseFloat(play.quantity).toFixed(
                      dashboardAPI.CRYPTO_MAPPED_TO_AMOUNT_DECIMAL_PRECISION[
                        play.crypto_symbol
                      ],
                    )}{' '}
                    {play.crypto_symbol}
                  </AlertText>
                </Alert>
                <Text>
                  {play.starting_transaction_type === 'buy'
                    ? 'Buying'
                    : 'Selling'}{' '}
                  Price @{' $'}
                  {parseFloat(play.price).toFixed(
                    dashboardAPI.CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION[
                      play.crypto_symbol
                    ],
                  )}{' '}
                  {currency}
                  {'/'}
                  {play.crypto_symbol}{' '}
                </Text>

                <Heading size={'sm'}>
                  Ending Transaction{endingTransactionStatus}
                </Heading>

                <Alert
                  mx="$2.5"
                  action={
                    play.starting_transaction_type === 'buy'
                      ? 'error'
                      : 'success'
                  }
                  variant={endingTransactionVariant}
                >
                  {endingTransactionStatus === '' ? (
                    <AlertText>
                      Awaiting{' '}
                      {play.starting_transaction_type === 'buy'
                        ? 'Buy'
                        : 'Sell'}{' '}
                      Order
                    </AlertText>
                  ) : (
                    <AlertText>
                      {endingTransactionStatus
                        .toLowerCase()
                        .includes('fulfilled') ? (
                        <>
                          {play.starting_transaction_type === 'buy'
                            ? 'SOLD'
                            : 'BOUGHT'}
                        </>
                      ) : (
                        <>
                          {play.starting_transaction_type === 'buy'
                            ? 'SELL'
                            : 'BUY'}
                        </>
                      )}{' '}
                      {(
                        play.ending_price * parseFloat(play.ending_quantity)
                      ).toFixed(2)}{' '}
                      {currency}
                      {' ~ '}
                      {parseFloat(play.ending_quantity).toFixed(
                        dashboardAPI.CRYPTO_MAPPED_TO_AMOUNT_DECIMAL_PRECISION[
                          play.crypto_symbol
                        ],
                      )}{' '}
                      {play.crypto_symbol}
                    </AlertText>
                  )}
                </Alert>

                <Text>
                  {play.starting_transaction_type === 'buy'
                    ? 'Selling'
                    : 'Buying'}{' '}
                  Price @{' $'}
                  {play.ending_price.toFixed(
                    dashboardAPI.CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION[
                      play.crypto_symbol
                    ],
                  )}{' '}
                  {currency}
                  {'/'}
                  {play.crypto_symbol}
                </Text>
              </VStack>
            ) : (
              <Center>
                <Loading></Loading>
              </Center>
            )}
          </ScrollView>
        </View>

        {play !== null && (
          <Actionsheet
            isOpen={showEditActionSheet}
            onClose={() => setShowEditActionSheet(!showEditActionSheet)}
            zIndex={999}
            snapPoints={[75]}
            useRNModal={true}
          >
            <ActionsheetBackdrop />
            <ActionsheetContent zIndex={999}>
              <ActionsheetDragIndicatorWrapper>
                <ActionsheetDragIndicator />
              </ActionsheetDragIndicatorWrapper>
              <ActionsheetSectionHeaderText>
                Edit Play
              </ActionsheetSectionHeaderText>

              <Box style={{ width: '100%' }}>
                <Center>
                  <HStack space={'3xl'}>
                    <CryptoAvatar size={'sm'} crypto={play.crypto_symbol} />
                    <Heading>
                      {play.starting_transaction_type === 'buy'
                        ? 'SELL'
                        : 'BUY'}{' '}
                      ${(amountInFiatCurrency + newGains).toFixed(2)} {currency}{' '}
                      @{' '}
                      {endPrice.toFixed(
                        dashboardAPI.CRYPTO_MAPPED_TO_PRICE_DECIMAL_PRECISION[
                          play.crypto_symbol
                        ],
                      )}
                    </Heading>
                  </HStack>

                  <RoiSpeedometer roigaugevalue={roi} />

                  <HStack space={'2xl'}>
                    <Box>
                      <Heading>
                        ${(totalInvestment * (originalRoi / 100.0)).toFixed(2)}{' '}
                        {currency}
                      </Heading>
                      <Text>Potential Gains</Text>
                    </Box>
                    <Avatar size={'sm'} bgColor="$green600">
                      {/* User is imported from 'lucide-react-native' */}
                      <AvatarFallbackText>⏭</AvatarFallbackText>
                    </Avatar>
                    <Box>
                      <Heading>
                        ${newGains.toFixed(2)} {currency}
                      </Heading>
                      <Text>New Gains</Text>
                    </Box>
                  </HStack>
                  <Heading>Increase ROI</Heading>

                  <Slider
                    //onChange={setQuantityOfFundsAndUpdateTotalInvestment}
                    onChange={setRoiIncreaseAndIncreaseRoi}
                    minValue={0}
                    maxValue={20}
                    defaultValue={0}
                    size="md"
                    orientation="horizontal"
                    isDisabled={false}
                    isReversed={false}
                    value={roiIncrease}
                    w={300}
                  >
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>

                  <HStack mt={20} space={'2xl'}>
                    <Button
                      size={'lg'}
                      isDisabled={roi === originalRoi}
                      onPress={() => {
                        const value = {
                          id: play.id,
                          new_ending_price: endPrice,
                          change_exit_price: true,
                        };

                        setShowEditActionSheet(false);
                        dashboardAPI.editPlay(user, value).then((x) => {
                          setPlay(null);
                          loadPlay();
                        });
                      }}
                    >
                      <ButtonText>Apply Change</ButtonText>
                    </Button>
                  </HStack>
                </Center>
              </Box>
            </ActionsheetContent>
          </Actionsheet>
        )}
      </GestureHandlerRootView>
      {showConfetti && (
        <ConfettiCannon
          count={50}
          origin={{
            y: PixelRatio.getPixelSizeForLayoutSize(height2) * 0.8,
            x: 0,
          }}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 10,
    paddingLeft: 10,
    // paddingTop: StatusBar.currentHeight,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
  header: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,

    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
  card_metric_savings: {
    // flex: 1,
    //backgroundColor: '#fff',
    //alignItems: 'center',
    // justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 5,
    marginLeft: 5,
    //marginTop: 5,
    //paddingBottom: 20,
    //paddingTop: 2,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: 'rgba(12, 95, 223, 1)',
  },
  card_metric: {
    // flex: 1,
    //backgroundColor: '#fff',
    //alignItems: 'center',
    // justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 5,
    marginLeft: 5,
    //marginTop: 5,
    //paddingBottom: 20,
    //paddingTop: 2,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#2A7948',
  },
});
