// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// Initialize Firebase

import {
  API_KEY,
  AUTH_DOMAIN,
  PROJECT_ID,
  STORAGE_BUCKET,
  MESSAGING_SENDER_ID,
  APP_ID,
  MEASUREMENT_ID,
} from '@env';

import {
  initializeAuth,
  getReactNativePersistence,
  getAuth,
} from 'firebase/auth';
import { GetAsyncStorage } from './GetAsyncStorage';
import { Platform } from 'react-native';
const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
};
let appName = 'CryptoPilot.app';
if (PROJECT_ID === 'letsbet-pr') {
  appName = 'letsbet';
}
const app = initializeApp(firebaseConfig, 'CryptoPilot.app');

// Export firestore database
// It will be imported into your react app whenever it is needed
const db_1 = getFirestore(app);
let auth2 = null;
if (Platform.OS === 'web') {
  auth2 = getAuth(app);
} else {
  auth2 = initializeAuth(app, {
    persistence: getReactNativePersistence(GetAsyncStorage()),
  });
}

export default function firebaseUtils(toolName) {
  if (toolName == 'db') {
    return db_1;
  }

  if (toolName == 'auth') {
    return auth2;
  }
}
