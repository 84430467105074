import React, { useContext, useEffect } from 'react';
import {
  Heading,
  Box,
  Text,
  HStack,
  ChevronUpIcon,
  ChevronDownIcon,
  Badge,
  BadgeText,
  BadgeIcon,
  ButtonText,
  Button,
} from '@gluestack-ui/themed';
import { AuthContext } from '../AuthProvider';
import CryptoAvatar from './shared/CryptoAvatar';
import {
  GoogleChartEditor,
  GoogleChartWrapper,
  GoogleViz,
  Chart,
} from 'react-google-charts';
import { StyleSheet } from 'react-native';

export default function EditManyPlays() {
  const { user } = useContext(AuthContext);

  const [chartEditor, setChartEditor] = React.useState(undefined);
  const [chartWrapper2, setChartWrapper] = React.useState(undefined);
  const [google2, setGoogle] = React.useState(undefined);

  const [totalGains, setTotalGains] = React.useState(0.0);
  const [recentPlays, setRecentPlays] = React.useState([]);
  const [openPlays, setOpenPlays] = React.useState([]);

  const [asset, setAsset] = React.useState('BTC');
  const [playType, setPlayType] = React.useState('BULL');
  const [selectedNode, setSelectedNode] = React.useState(null);

  useEffect(() => {
    (async () => {})();
  }, []);

  const data = [
    ['From', 'To', 'Weight'],

    ['Pending Sell', '%5 Above Market', 5],
    ['Pending Sell', '%3 Above Market', 7],

    ['Pending Sell', '%10+ Above Market', 6],
    ['Pending Buy', '%5 Below Market', 5],

    ['Pending Buy', '%7 Below Market', 6],
    ['Pending Buy', '%10+ Below Market', 6],
  ];

  const options = {
    sankey: {
      node: {
        nodePadding: 20,
        width: 35,
        labelPadding: 2,
        interactivity: true,
      },
    },
  };

  const chartSelect = (selection) => {
    console.log('selected ' + selection);
  };

  return (
    <>
      <Box w={'$95'} h={'600px'} style={styles.card}>
        <HStack space={'2xl'}>
          <Heading>Edit Many Plays</Heading>
          <Box>
            <Text>Crypto</Text>
            <Button size={'lg'} variant={'outline'} action={'primary'}>
              <CryptoAvatar crypto={asset} />
              <ButtonText>{asset === 'cash' ? 'Cash' : asset}</ButtonText>
            </Button>
          </Box>
          <Box>
            <Text>Plays</Text>
            <Button size={'lg'} variant={'outline'} action={'primary'}>
              <ButtonText>{playType}</ButtonText>
            </Button>
          </Box>
        </HStack>
        {selectedNode === null ? (
          <Chart
            chartType="Sankey"
            width="100%"
            height="500px"
            data={data}
            options={options}
            chartPackages={['corechart', 'controls', 'charteditor']}
            getChartEditor={({ chartEditor, chartWrapper, google }) => {
              setChartEditor(chartEditor);
              setChartWrapper(chartWrapper);
              setGoogle(google);
            }}
            chartEvents={[
              {
                eventName: 'select',
                callback: (chartWrapper) => {
                  setSelectedNode(chartWrapper2.getChart().getSelection());
                },
              },
              {
                eventName: 'error',
                callback: ({ eventArgs }) => {
                  console.log('error');
                  console.log(eventArgs);
                },
              },
              {
                eventName: 'ready',
                callback: ({ chartWrapper }) => {
                  setChartWrapper(chartWrapper);
                  console.log('ready');
                },
              },
            ]}
          />
        ) : (
          <Button size={'lg'} onPress={() => setSelectedNode(null)}>
            <ButtonText>Reset</ButtonText>
          </Button>
        )}
      </Box>

      {/*<Box       bg="#D3D3D3">*/}
      {/*    /!*<Button><ButtonText></ButtonText></Button>*!/*/}
      {/*    <Heading>Sell Price 2500 5-7% Above Market</Heading>*/}
      {/*    <Text>30$</Text>*/}

      {/*</Box>*/}
      {/*<br/>*/}

      {/*<Box       bg="#D3D3D3">*/}
      {/*    /!*<Button><ButtonText></ButtonText></Button>*!/*/}
      {/*    <Heading>Sell Price 2250 3-5% Above Market</Heading>*/}
      {/*    <Text>30$</Text>*/}

      {/*</Box>*/}
      {/*<br/>*/}

      {/*<Box       bg="#D3D3D3">*/}
      {/*    /!*<Button><ButtonText></ButtonText></Button>*!/*/}
      {/*    <Heading>Sell Price 2180 3% Above Market</Heading>*/}
      {/*    <Text>30$</Text>*/}

      {/*</Box>*/}
      {/*<br/>*/}
      {/*<Badge size="md" variant="solid" borderRadius="$none" action="error">*/}
      {/*    <BadgeText>Pending Sell Orders</BadgeText>*/}
      {/*    <BadgeIcon as={ChevronUpIcon} ml="$2" />*/}
      {/*</Badge>*/}
      {/*<br/>*/}

      {/*  <Box       bg="#D3D3D3">*/}
      {/*      <CryptoAvatar crypto={'ETH'} size={'lg'}/>*/}
      {/*      <Heading>ETH</Heading>*/}
      {/*      <Heading>Price:2100</Heading>*/}
      {/*      <Heading>Pending Orders:</Heading>*/}
      {/*      <HStack space={'lg'}><Text>Buy: $77</Text> <Text>Sell: $90</Text> </HStack>*/}
      {/*  </Box>*/}
      {/*<br/>*/}
      {/*<Badge size="md" variant="solid" borderRadius="$none" action="success">*/}
      {/*    <BadgeText>Pending Buy Orders</BadgeText>*/}
      {/*    <BadgeIcon as={ChevronDownIcon} ml="$2" />*/}
      {/*</Badge>*/}
      {/*<br/>*/}
      {/*<Box       bg="#D3D3D3">*/}
      {/*    /!*<Button><ButtonText></ButtonText></Button>*!/*/}
      {/*    <Heading>Buy Price 1950 - 3% Below Market</Heading>*/}

      {/*</Box>*/}
      {/*<br/>*/}

      {/*<Box       bg="#D3D3D3">*/}
      {/*    /!*<Button><ButtonText></ButtonText></Button>*!/*/}
      {/*    <Heading>Sell Price 1900 3-5% Below Market</Heading>*/}

      {/*</Box>*/}
      {/*<br/>*/}

      {/*<Box       bg="#D3D3D3">*/}
      {/*    /!*<Button><ButtonText></ButtonText></Button>*!/*/}
      {/*    <Heading>Sell Price 1800 5-7% Below Market</Heading>*/}

      {/*</Box>*/}
      {/*<br/>*/}
    </>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    paddingBottom: 10,
    paddingTop: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
});
