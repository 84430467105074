import React, { useContext, useEffect } from 'react';
import {
  Button,
  Text,
  ButtonText,
  Heading,
  Center,
  Image,
  FormControl,
  FormControlLabel,
  FormControlLabelText,
  Input,
  InputField,
  VStack,
  Divider,
  HStack,
  ButtonIcon,
  Toast,
  ToastTitle,
  useToast,
} from '@gluestack-ui/themed';
import { View } from 'react-native';
import { StyleSheet, ImageBackground, SafeAreaView } from 'react-native';
import logo from '../../assets/LOGO2.png';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { AuthContext } from '../../AuthProvider';
import { useNavigation } from '@react-navigation/native';
import { signInWithEmailAndPassword } from 'firebase/auth';
import firebaseUtils from '../../Firebase';
import Loading from './Loading';

const Login = (params) => {
  const { login } = useContext(AuthContext);
  const navigation = useNavigation();

  const [robinhoodPassword, setRobinhoodPassword] = React.useState('');

  const [robinhoodEmail, setRobinhoodEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');

  useEffect(() => {}, []);
  const toast = useToast();

  const googleLogin = () => {
    login();
  };

  const createUser = () => {
    setLoading(true);
    setErrorMsg('');
    signInWithEmailAndPassword(
      firebaseUtils('auth'),
      robinhoodEmail,
      robinhoodPassword,
    )
      .then((userCredential) => {
        navigation.navigate(params.redirect);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        setErrorMsg(e);
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable To Log In</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <VStack w="$full" p={20}>
            <FormControl mt={5}>
              <FormControlLabel>
                <FormControlLabelText>Email</FormControlLabelText>
              </FormControlLabel>
              <Input
                aria-label={'CryptoPilot Account Username'}
                size={'lg'}
                w="$full"
              >
                <InputField
                  value={robinhoodEmail}
                  onChangeText={setRobinhoodEmail}
                  variant="rounded"
                />
              </Input>
              <FormControlLabel>
                <FormControlLabelText>Password</FormControlLabelText>
              </FormControlLabel>
              <Input
                aria-label={'CryptoPilot Account Password'}
                size={'lg'}
                w="$full"
              >
                <InputField
                  value={robinhoodPassword}
                  onChangeText={setRobinhoodPassword}
                  type={'password'}
                  variant="rounded"
                />
              </Input>

              {errorMsg != '' && <Text>{errorMsg.toString()}</Text>}

              <Button onPress={() => createUser()} mt={20}>
                <ButtonText>Log In</ButtonText>
              </Button>
            </FormControl>
          </VStack>
          <HStack space="sm" mb="$4" alignItems="center">
            <Divider w={80} />
            <Text size="sm">OR</Text>
            <Divider w={80} />
          </HStack>
          <Button
            title="Sign in with Google"
            onPress={() => googleLogin()}
            buttonStyle={styles.googleSignInButton}
            textStyle={styles.buttonText}
          >
            <ButtonIcon style={styles.buttonIcon}>
              <FontAwesomeIcon icon={faGoogle} color="white" size={15} />
            </ButtonIcon>
            <ButtonText> Log in with Google</ButtonText>
          </Button>
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
  },
  backgroundImage: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  googleSignInButton: {
    backgroundColor: 'blue',
    paddingHorizontal: 30,
    paddingVertical: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
  },
  card: {
    backgroundColor: '#fff',
    marginRight: 20,
    marginLeft: 20,
    marginTop: 3,
    paddingBottom: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
  signupCard: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 10,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
  buttonIcon: {
    marginRight: 5,
    marginTop: 2,
  },
});

export default Login;
