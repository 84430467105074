import React, { useContext, useEffect } from 'react';
import {
  Button,
  ButtonText,
  Heading,
  VStack,
  Toast,
  ToastTitle,
  useToast,
  Center,
  Text,
} from '@gluestack-ui/themed';

import { AuthContext } from '../AuthProvider';

import { Dimensions, Platform, ScrollView, StyleSheet } from 'react-native';
import { TouchableOpacity, SafeAreaView } from 'react-native';
import Login from './shared/Login';
import { dashboardAPI } from '../DashboardAPI';
import { useNavigation } from '@react-navigation/native';
import Loading from './shared/Loading';

export default function DeleteUser({ route }) {
  const { user, exchangePrices, setUser } = useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    (async () => {})();
  }, [user]);

  const toast = useToast();
  const navigation = useNavigation();

  const deleteUser = () => {
    setLoading(true);
    dashboardAPI.deleteUser(user).then((x) => {
      setUser(false);
      navigation.navigate('Home');
      toast.show({
        placement: 'top',
        render: ({ id }) => {
          return (
            <Toast nativeId={id} variant="solid">
              <VStack space="xs">
                <ToastTitle>Account Deleted</ToastTitle>
              </VStack>
            </Toast>
          );
        },
      });
    });
  };

  return (
    <SafeAreaView style={{ flex: 8 }}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {user !== false ? (
            <Center>
              {user.email !== 'javier.andres.tovar.ramirez@gmail.com' ? (
                <>
                  <Heading>Delete User Account And Data</Heading>
                  <Text>{user.email}</Text>
                  <Button onPress={deleteUser}>
                    <ButtonText>Delete</ButtonText>
                  </Button>
                </>
              ) : (
                <Text>Unable Delete Demo Account</Text>
              )}
            </Center>
          ) : (
            <Center>
              <Heading>Login To Delete Account</Heading>
              <Login redirect={'DeleteUser'} />
            </Center>
          )}
        </>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
});
