import React, { useContext, useEffect } from 'react';
import {
  Button,
  ButtonText,
  Heading,
  VStack,
  Toast,
  ToastTitle,
  useToast,
  Center,
  Text,
  Box,
} from '@gluestack-ui/themed';

import {
  Dimensions,
  PixelRatio,
  Platform,
  ScrollView,
  StyleSheet,
} from 'react-native';
import { SafeAreaView } from 'react-native';
import { WebView } from 'react-native-webview';

export default function BinanceGuide({ route }) {
  useEffect(() => {
    (async () => {})();
  }, []);

  const toast = useToast();

  function copyToClipboard(text) {
    (async () => {
      try {
        await navigator.clipboard.writeText(text);
        /* ✅ Copied successfully */

        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="info" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Link Copied To Clipboard</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      } catch (e) {
        /* ❌ Failed to copy (insufficient permissions) */
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable To Copy Link</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      }
    })();
  }

  let width = Dimensions.get('screen').width;
  let height = Dimensions.get('screen').height;

  return (
    <SafeAreaView style={{ flex: 8 }}>
      <Center mb={4}>
        <Button
          size={'lg'}
          action={'secondary'}
          onPress={() =>
            copyToClipboard('https://cryptopilot.app/binance_guide')
          }
        >
          <ButtonText>Copy Link To Guide</ButtonText>
        </Button>
      </Center>

      {Platform.OS === 'web' ? (
        <iframe
          src="https://scribehow.com/embed/Binance_Account_Set_Up_On_CryptoPilot__FKp1m0y6T36_8rC0dXEaxw?as=scrollable"
          width="100%"
          height={
            '' +
            (PixelRatio.getPixelSizeForLayoutSize(height) * 0.87).toFixed(0) +
            ' px'
          }
          allowFullScreen
          frameBorder="0"
        ></iframe>
      ) : (
        <WebView
          scalesPageToFit={true}
          bounces={false}
          javaScriptEnabled
          style={{ height: height * 0.87, width: '100%' }}
          source={{
            html: `
                  <!DOCTYPE html>
                  <html>
                    <head></head>
                    <body>
                      
<iframe src="https://scribehow.com/embed/Binance_Account_Set_Up_On_CryptoPilot__FKp1m0y6T36_8rC0dXEaxw?as=scrollable"  width="100%"
          height="${(
            PixelRatio.getPixelSizeForLayoutSize(height) * 0.87
          ).toFixed(0)}px" allowfullscreen frameborder="0"></iframe>
                    </body>
                  </html>
            `,
          }}
          automaticallyAdjustContentInsets={false}
        />
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    //alignItems: 'center',
    // justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
});
