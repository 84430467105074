import {
  Avatar,
  AvatarFallbackText,
  CalendarDaysIcon,
  ClockIcon,
  HStack,
  Icon,
  Text,
} from '@gluestack-ui/themed';

export default function DurationAvatar(params) {
  let color = 'black';
  if (params.color !== undefined && params.color !== null) {
    color = params.color;
  }

  let horizontal = false;
  if (params.horizontal !== undefined && params.horizontal !== null) {
    horizontal = params.horizontal;
  }

  let took_number = (params.duration / (60 * 60 * 1000)).toFixed(0);
  let took_in = 'Hours';
  let took_in_hours = true;
  if (took_number == 1) {
    took_in = 'Hour';
  }
  if (60 * 60 * 1000 > params.duration) {
    took_in = 'Minutes';
    took_number = (params.duration / (60 * 1000)).toFixed(0);
  }

  if (params.duration / (60 * 60 * 1000) > 50) {
    took_number = (params.duration / (24 * 60 * 60 * 1000)).toFixed(0);
    took_in_hours = false;
    if (took_number == 1) {
      took_in = 'Day';
    } else {
      took_in = 'Days';
    }
  }
  if (!horizontal) {
    return (
      <>
        <Avatar
          size={'sm'}
          borderColor={color === 'black' ? '$secondary600' : '#fff'}
          borderWidth={1}
          bgColor={color === 'black' ? '$coolGray200' : '$gray100'}
        >
          {/* User is imported from 'lucide-react-native' */}
          {took_in_hours ? (
            <Icon
              as={ClockIcon}
              color={color === 'black' ? '$secondary600' : '#fff'}
              size={'lg'}
            />
          ) : (
            <Icon
              as={CalendarDaysIcon}
              color={color === 'black' ? '$secondary600' : '#fff'}
              size={'lg'}
            />
          )}
        </Avatar>

        <Text color={color === 'black' ? 'black' : '$primary500'} bold={true}>
          {took_number} {took_in}
        </Text>
      </>
    );
  }
  return (
    <HStack>
      <Avatar
        size={'sm'}
        borderColor={color === 'black' ? '$secondary600' : '#fff'}
        borderWidth={1}
        bgColor={color === 'black' ? '$coolGray200' : '$gray100'}
      >
        {/* User is imported from 'lucide-react-native' */}
        {took_in_hours ? (
          <Icon
            as={ClockIcon}
            color={color === 'black' ? '$secondary600' : '#fff'}
            size={'lg'}
          />
        ) : (
          <Icon
            as={CalendarDaysIcon}
            color={color === 'black' ? '$secondary600' : '#fff'}
            size={'lg'}
          />
        )}
      </Avatar>

      <Text color={color === 'black' ? 'black' : '$primary500'} bold={true}>
        {took_number} {took_in_hours ? 'Hours' : 'Days'}
      </Text>
    </HStack>
  );
}
