import {
  Avatar,
  AvatarFallbackText,
  Box,
  Center,
  Heading,
  HStack,
  Badge,
  BadgeText,
  Button,
  ButtonText,
  Text,
  AlertText,
  Alert,
  ButtonGroup,
} from '@gluestack-ui/themed';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import CryptoAvatar from './CryptoAvatar';
import DurationAvatar from './DurationAvatar';
import { dashboardAPI } from '../../DashboardAPI';
import { AuthContext } from '../../AuthProvider';
import { useContext } from 'react';

export default function Fund(params) {
  const { user } = useContext(AuthContext);

  const navigation = useNavigation();

  const viewPlayDetails = (play_id) => {
    navigation.navigate('PlayDetails', {
      play: play_id,
    });
  };
  /*
        amount_in_fiat_currency: "1.01"
    
        amount_in_usd: 1.01
    
        asset: "cash"
    
        currency: "usd"
    
        fund_expiring_setting: "non_expirable"
    
        id: "00028714-fa1f-49a6-8c87-403bcc2d2e88"
    
        market_app: "robinhood"
    
        number_of_funds_to_make: 69
    
        status: "in use"
    
        user: "javilin93@gmail.com"
    
     */
  return (
    <>
      <Box style={styles.card}>
        <CryptoAvatar crypto={params.fund.asset} size={'sm'} />
        <Box>
          <Heading size={'sm'}>
            {params.fund.asset === 'cash' ? 'Cash' : params.fund.asset}{' '}
          </Heading>
          {params.fund.asset === 'cash' &&
            params.fund.currency !== undefined &&
            params.fund.currency === 'usd' && (
              <>
                <Text>{params.fund.amount_in_usd} USD</Text>
              </>
            )}
          {params.fund.asset === 'cash' &&
            params.fund.currency !== undefined &&
            params.fund.currency === 'mxn' && (
              <>
                <Text>{params.fund.amount_in_mxn} MXN</Text>
              </>
            )}
          {params.fund.asset === 'cash' &&
            params.fund.currency !== undefined &&
            params.fund.currency === 'cop' && (
              <>
                <Text>{params.fund.amount_in_cop} COP</Text>
              </>
            )}
        </Box>
        <Alert>
          <AlertText>
            {params.fund.status === 'in use' ? 'Fund In Use' : 'Fund Is Open'}
          </AlertText>
        </Alert>
        <ButtonGroup>
          {params.fund.current_play_id !== undefined && (
            <Button
              onPress={() => viewPlayDetails(params.fund.current_play_id)}
            >
              <ButtonText>View Play</ButtonText>
            </Button>
          )}

          {user !== false && (
            <Button
              onPress={() => {
                dashboardAPI.deleteFund(user, params.fund.id).then((x) => {
                  navigation.navigate('Home');
                });
              }}
            >
              <ButtonText>Cancel</ButtonText>
            </Button>
          )}
        </ButtonGroup>
      </Box>
    </>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
});
