import {
  Avatar,
  AvatarFallbackText,
  Box,
  Center,
  Heading,
  HStack,
  Badge,
  BadgeText,
  Button,
  ButtonText,
  Text,
  LoaderIcon,
  BadgeIcon,
  ClockIcon,
} from '@gluestack-ui/themed';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import CryptoAvatar from './CryptoAvatar';
import DurationAvatar from './DurationAvatar';
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../AuthProvider';

export default function Play(params) {
  const { exchangePrices } = useContext(AuthContext);

  const [startingTransactionInfo, setStartingTransactionInfo] = useState('');
  const [endingTransactionInfo, setEndingTransactionInfo] = useState('');
  const [riskMetric, setRiskMetric] = useState(0);
  const [rewardMetric, setRewardMetric] = useState(0);
  const [gains, setGains] = useState(0.0);

  const navigation = useNavigation();
  let timestamp = null;

  let robinhoodAndBinance = ['robinhood', 'binance'];

  if (params.play.status === 'closed') {
    const timeLabel = new Date(params.play.last_upd_ts);
    timestamp = `${timeLabel.toLocaleDateString()} ${timeLabel.toLocaleTimeString()}`;
  } else {
    const timeLabel = new Date(params.play.create_ts);
    timestamp = `${timeLabel.toLocaleDateString()} ${timeLabel.toLocaleTimeString()}`;
  }

  useEffect(() => {
    if (exchangePrices !== false) {
      let playGains = 0.0;
      if (params.play.starting_transaction_type === 'buy') {
        playGains =
          params.play.ending_price / parseFloat(params.play.price) - 1;
      }

      if (params.play.starting_transaction_type === 'sell') {
        playGains =
          parseFloat(params.play.price) / params.play.ending_price - 1;
      }

      if (
        params.play.starting_transaction_type === 'buy' &&
        params.play.status === 'closed' &&
        params.play.purpose === 'crypto'
      ) {
        let gains_and_savings =
          parseFloat(params.play.quantity) -
          parseFloat(params.play.ending_quantity);
        let theorical_gains = parseFloat(params.play.quantity) * playGains;
        let savings = gains_and_savings - theorical_gains;
        if (savings > 0.0) {
          if (robinhoodAndBinance.includes(params.play.market_app)) {
            setRewardMetric(
              theorical_gains * exchangePrices[params.play.crypto_symbol],
            );
          }

          if (
            params.play.market_app === 'bitso' &&
            params.play.currency === 'mxn'
          ) {
            setRewardMetric(
              theorical_gains *
                exchangePrices[params.play.crypto_symbol + '_MXN'],
            );
          }

          if (
            params.play.market_app === 'bitso' &&
            params.play.currency === 'usd'
          ) {
            setRewardMetric(
              theorical_gains * exchangePrices[params.play.crypto_symbol],
            );
          }

          if (
            params.play.market_app === 'bitso' &&
            params.play.currency === 'cop'
          ) {
            setRewardMetric(
              theorical_gains *
                exchangePrices[params.play.crypto_symbol + '_COP'],
            );
          }
        } else {
          if (robinhoodAndBinance.includes(params.play.market_app)) {
            setRewardMetric(
              (parseFloat(params.play.quantity) -
                parseFloat(params.play.ending_quantity)) *
                exchangePrices[params.play.crypto_symbol],
            );
          }

          if (
            params.play.market_app === 'bitso' &&
            params.play.currency === 'mxn'
          ) {
            setRewardMetric(
              (parseFloat(params.play.quantity) -
                parseFloat(params.play.ending_quantity)) *
                exchangePrices[params.play.crypto_symbol + '_MXN'],
            );
          }
          if (
            params.play.market_app === 'bitso' &&
            params.play.currency === 'cop'
          ) {
            setRewardMetric(
              (parseFloat(params.play.quantity) -
                parseFloat(params.play.ending_quantity)) *
                exchangePrices[params.play.crypto_symbol + '_COP'],
            );
          }
          if (
            params.play.market_app === 'bitso' &&
            params.play.currency === 'usd'
          ) {
            setRewardMetric(
              (parseFloat(params.play.quantity) -
                parseFloat(params.play.ending_quantity)) *
                exchangePrices[params.play.crypto_symbol],
            );
          }
        }
      } else if (robinhoodAndBinance.includes(params.play.market_app)) {
        setRewardMetric(params.play.amount_in_usd * playGains);
      } else if (
        params.play.market_app === 'bitso' &&
        params.play.currency === 'mxn'
      ) {
        setRewardMetric(params.play.amount_in_mxn * playGains);
      } else if (
        params.play.market_app === 'bitso' &&
        params.play.currency === 'usd'
      ) {
        setRewardMetric(params.play.amount_in_usd * playGains);
      } else if (
        params.play.market_app === 'bitso' &&
        params.play.currency === 'cop'
      ) {
        setRewardMetric(params.play.amount_in_cop * playGains);
      }
      setGains(playGains);

      if (
        robinhoodAndBinance.includes(params.play.market_app) &&
        params.play.starting_transaction_type === 'buy'
      ) {
        setStartingTransactionInfo(
          'BUY ' + params.play.amount_in_usd.toFixed(2) + ' USD',
        );
        setEndingTransactionInfo(
          'SELL ' +
            (params.play.amount_in_usd * (1 + playGains)).toFixed(2) +
            ' USD',
        );
      }
      if (
        robinhoodAndBinance.includes(params.play.market_app) &&
        params.play.starting_transaction_type === 'sell'
      ) {
        setStartingTransactionInfo(
          'SELL ' + params.play.amount_in_usd.toFixed(2) + ' USD',
        );
        setEndingTransactionInfo(
          'BUY ' +
            (params.play.amount_in_usd * (1 + playGains)).toFixed(2) +
            ' USD',
        );
      }
      if (
        params.play.market_app === 'bitso' &&
        params.play.starting_transaction_type === 'buy'
      ) {
        if (params.play.amount_in_mxn) {
          setStartingTransactionInfo(
            'BUY ' + parseFloat(params.play.amount_in_mxn).toFixed(0) + ' MXN',
          );
          setEndingTransactionInfo(
            'SELL ' +
              (parseFloat(params.play.amount_in_mxn) * (1 + playGains)).toFixed(
                0,
              ) +
              ' MXN',
          );
        }
        if (params.play.amount_in_usd) {
          setStartingTransactionInfo(
            'BUY ' + parseFloat(params.play.amount_in_usd).toFixed(2) + ' USD',
          );
          setEndingTransactionInfo(
            'SELL ' +
              (parseFloat(params.play.amount_in_usd) * (1 + playGains)).toFixed(
                2,
              ) +
              ' USD',
          );
        }
        if (params.play.amount_in_cop) {
          setStartingTransactionInfo(
            'BUY ' + parseFloat(params.play.amount_in_cop).toFixed(0) + ' COP',
          );
          setEndingTransactionInfo(
            'SELL ' +
              (parseFloat(params.play.amount_in_cop) * (1 + playGains)).toFixed(
                0,
              ) +
              ' COP',
          );
        }
      }
      if (
        params.play.market_app === 'bitso' &&
        params.play.starting_transaction_type === 'sell'
      ) {
        if (params.play.amount_in_mxn) {
          setStartingTransactionInfo(
            'SELL ' + parseFloat(params.play.amount_in_mxn).toFixed(0) + ' MXN',
          );
          setEndingTransactionInfo(
            'BUY ' +
              (parseFloat(params.play.amount_in_mxn) * (1 + playGains)).toFixed(
                0,
              ) +
              ' MXN',
          );
        }
        if (params.play.amount_in_cop) {
          setStartingTransactionInfo(
            'SELL ' + parseFloat(params.play.amount_in_cop).toFixed(0) + ' COP',
          );
          setEndingTransactionInfo(
            'BUY ' +
              (parseFloat(params.play.amount_in_cop) * (1 + playGains)).toFixed(
                0,
              ) +
              ' COP',
          );
        }
        if (params.play.amount_in_usd) {
          setStartingTransactionInfo(
            'SELL ' + parseFloat(params.play.amount_in_usd).toFixed(2) + ' USD',
          );
          setEndingTransactionInfo(
            'BUY ' +
              (parseFloat(params.play.amount_in_usd) * (1 + playGains)).toFixed(
                2,
              ) +
              ' USD',
          );
        }
      }

      if (playGains < 0.03) {
        setRiskMetric(1.0);
      } else if (playGains < 0.05) {
        setRiskMetric(2.0);
      } else if (playGains < 0.07) {
        setRiskMetric(3.0);
      } else if (playGains < 0.09) {
        setRiskMetric(4.0);
      } else {
        setRiskMetric(5.0);
      }
    }
  }, [exchangePrices]);

  let took = Date.now() - Date.parse(params.play.create_ts);
  if (params.play.status === 'closed') {
    took =
      Date.parse(params.play.last_upd_ts) - Date.parse(params.play.create_ts);
  }

  const play_source_labels = {
    ai: 'Created By Artificial Intelligence',
    indicators: 'Created By Tech Analysis',
    tradingview: 'Created By TradingView',
    user: 'Created By User',
  };

  const getDetailedStatus = () => {
    if (params.play.detailed_status !== undefined) {
      if (
        params.play.detailed_status.ending_transaction_has_been_fulfilled !==
          undefined &&
        new Date(
          params.play.detailed_status.ending_transaction_has_been_fulfilled,
        ).getTime() > 0
      ) {
        return (
          <Badge size="lg">
            <BadgeText>Play Completed</BadgeText>
          </Badge>
        );
      }
      if (
        params.play.detailed_status.ending_transaction_submitted !==
          undefined &&
        new Date(
          params.play.detailed_status.ending_transaction_submitted,
        ).getTime() > 0
      ) {
        return (
          <Badge action={'muted'} size="lg">
            <BadgeText>
              {params.play.starting_transaction_type === 'buy'
                ? 'Awaiting Sell Order'
                : 'Awaiting Buy Order'}
            </BadgeText>
            <BadgeIcon as={ClockIcon} ml="$2" />
          </Badge>
        );
      }
      if (
        params.play.detailed_status.starting_transaction_has_been_fulfilled !==
          undefined &&
        new Date(
          params.play.detailed_status.starting_transaction_has_been_fulfilled,
        ).getTime() > 0
      ) {
        return (
          <Badge action={'muted'} size="lg">
            <BadgeText>
              {params.play.starting_transaction_type === 'buy'
                ? 'Buy Order Fulfilled'
                : 'Sell Order Fulfilled'}
            </BadgeText>
          </Badge>
        );
      }
      if (
        params.play.detailed_status.starting_transaction_submitted !==
          undefined &&
        new Date(
          params.play.detailed_status.starting_transaction_submitted,
        ).getTime() > 0
      ) {
        return (
          <Badge action={'muted'} size="lg">
            <BadgeText>
              {params.play.starting_transaction_type === 'buy'
                ? 'Awaiting Buy Order'
                : 'Awaiting Sell Order'}
            </BadgeText>
            <BadgeIcon as={ClockIcon} ml="$2" />
          </Badge>
        );
      }
    }
  };

  const viewPlayDetails = () => {
    navigation.navigate('PlayDetails', {
      play: params.play.id,
    });
  };

  return (
    <>
      <Box style={styles.card}>
        <Center>
          {getDetailedStatus()}

          <HStack space="md">
            <CryptoAvatar crypto={params.play.crypto_symbol} size={'sm'} />
            <Box>
              <Heading size={'sm'}>
                {params.play.crypto_symbol} -{' '}
                {params.play.starting_transaction_type === 'buy'
                  ? '📈BULL'
                  : '📉BEAR'}{' '}
                Play
              </Heading>
              <HStack space={'md'}>
                <Badge
                  size="lg"
                  variant={
                    params.play.starting_order_has_been_fulfilled !==
                      undefined &&
                    params.play.starting_order_has_been_fulfilled === true
                      ? 'outlined'
                      : 'solid'
                  }
                  action={
                    params.play.starting_transaction_type === 'buy'
                      ? 'success'
                      : 'error'
                  }
                >
                  <BadgeText>{startingTransactionInfo}</BadgeText>
                </Badge>

                <Badge
                  size="lg"
                  variant={
                    params.play.detailed_status !== undefined &&
                    params.play.detailed_status
                      .ending_transaction_has_been_fulfilled !== undefined
                      ? 'outlined'
                      : 'solid'
                  }
                  action={
                    params.play.starting_transaction_type === 'buy'
                      ? 'error'
                      : 'success'
                  }
                >
                  <BadgeText>{endingTransactionInfo}</BadgeText>
                </Badge>
              </HStack>

              <Text>{timestamp}</Text>
            </Box>
          </HStack>
          <HStack space={'lg'}>
            <Center>
              {riskMetric === 1 && <Text size={'3xl'}>💰</Text>}
              {riskMetric === 2 && <Text size={'3xl'}>💰💰</Text>}
              {riskMetric === 3 && <Text size={'3xl'}>💰💰💰</Text>}
              {riskMetric === 4 && <Text size={'3xl'}>💰💰💰💰</Text>}
              {riskMetric === 5 && <Text size={'3xl'}>💰💰💰💰💰</Text>}
            </Center>
          </HStack>

          <HStack space={'lg'}>
            <Center>
              <Avatar
                borderColor="$secondary600"
                borderWidth={1}
                size={'sm'}
                bgColor="$coolGray200"
              >
                {/* User is imported from 'lucide-react-native' */}
                <AvatarFallbackText color={'$secondary600'}>
                  %
                </AvatarFallbackText>
              </Avatar>
              <Text bold={true}>ROI {(gains * 100).toFixed(0)}%</Text>
            </Center>

            <Center>
              <Avatar
                size={'sm'}
                bgColor="$coolGray200"
                borderColor="$secondary600"
                borderWidth={1}
              >
                {/* User is imported from 'lucide-react-native' */}
                <AvatarFallbackText color={'$secondary600'}>
                  $
                </AvatarFallbackText>
              </Avatar>
              <Text bold={true}>
                {rewardMetric.toFixed(2)} {params.play.currency.toUpperCase()}
              </Text>
            </Center>
            {params.play.status === 'closed' && (
              <Center>
                <DurationAvatar duration={took} />
              </Center>
            )}
          </HStack>
          {params.viewtoggle && (
            <Button size={'lg'} mb={5} onPress={viewPlayDetails}>
              <ButtonText>View</ButtonText>
            </Button>
          )}
          {!params.viewtoggle && (
            <>
              {params.play.play_source_label !== undefined &&
                play_source_labels[params.play.play_source_label] && (
                  <Badge
                    mt={7}
                    size="md"
                    variant="outline"
                    borderRadius="$lg"
                    action="info"
                  >
                    <BadgeText>
                      {play_source_labels[params.play.play_source_label]}
                    </BadgeText>
                  </Badge>
                )}
            </>
          )}
        </Center>
      </Box>
    </>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 10,
    paddingLeft: 10,
    // paddingTop: StatusBar.currentHeight,
    marginRight: 10,
    marginLeft: 10,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
  },
});
